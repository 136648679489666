<!-- <p-toast></p-toast> -->
<app-navbar-main></app-navbar-main>
<main>
  <router-outlet class="">
    <div class="circles position-relative">
      <span class="circle-1 position-absolute">

      </span>
      <span class="circle-2 position-absolute">

      </span>
    </div>
  </router-outlet>
</main>
<p-scrollTop></p-scrollTop>
<app-footer></app-footer>
