import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { environment as env } from '../environments/environment'
import { ICart, ICartItem, ICartNumber } from '../models/interfaces/cart.model';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  apiURL = `${env.apiUrl}cart/`;
  constructor(private http: HttpClient) { }
  addToCart(items: Partial<ICartItem>[], esimData?: any): Observable<any> {
    return this.http.patch<any>(`${this.apiURL}`, { products: items, esim_data: esimData }, { withCredentials: true }).pipe(catchError(err => {
      // console.log(err?.error?.message)
      throw new Error(err?.error?.message);
    }));
  }
  getCart(currency: string = 'USD'): Observable<ICart> {
    return this.http.get<ICart>(`${this.apiURL}?currency=${currency}`, { withCredentials: true })
      .pipe(catchError(err => {
        throw new Error(err.message);
      }));
  }
  getCartNumber(): Observable<ICartNumber> {
    return this.http.get<ICartNumber>(`${this.apiURL}cart-numbers`, { withCredentials: true }).pipe(catchError(err => {
      throw new Error(err.message);
    }));
  }

}
