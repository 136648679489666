import { Action, createAction, props } from "@ngrx/store";
import { IWallet } from "../models/interfaces/wallet.model";
/************* get all Wallets for user ************************/
export const GET_ALL_WALLETS_USER = createAction('[Wallets List] getAllWalletsForUser');
export const GET_ALL_WALLETS_USER_SUCCESS = createAction('[Wallets List] getAllWalletsForUser success', props<{ payload: Array<any> }>());
export const GET_ALL_WALLETS_USER_FAIL = createAction('[Wallets List] getAllWalletsForUser fail', props<{ payload: Array<any> }>());
/************* get all Wallets ************************/
export const GET_ALL_WALLETS = createAction('[Wallets List] getAllWallets');
export const GET_ALL_WALLETS_SUCCESS = createAction('[Wallets List] getAllWallets success', props<{ payload: Array<IWallet> }>());
export const GET_ALL_WALLETS_FAIL = createAction('[Wallets List] getAllWallets fail', props<{ payload: Array<any> }>());
/************* get movements for user ************************/
export const GET_USER_MOVEMENTS = createAction('[Movements List] getUserMovements', props<{ payload: string }>());
export const GET_USER_MOVEMENTS_SUCCESS = createAction('[Movements List] getUserMovements success', props<{ payload: any }>());
export const GET_USER_MOVEMENTS_FAIL = createAction('[Movements List] getUserMovements fail', props<{ payload: any }>());
/************* get user movements ************************/
export const GET_MOVEMENTS = createAction('[Movements List] getMovements', props<{ payload: string }>());
export const GET_MOVEMENTS_SUCCESS = createAction('[Movements List] getMovements success', props<{ payload: any }>());
export const GET_MOVEMENTS_FAIL = createAction('[Movements List] getMovements fail', props<{ payload: any }>());
/************* create Wallet ************************/
export const CREATE_WALLET = createAction('[Create Wallets] createWallet', props<{ payload: IWallet }>());
export const CREATE_WALLET_SUCCESS = createAction('[Create Wallets] createWallet success', props<{ payload: IWallet }>());
export const CREATE_WALLET_FAIL = createAction('[Create Wallets] createWallet fail', props<{ payload: any }>());
/************* create Wallet ************************/
export const WALLET_BALANCE = createAction('[Wallet Balance] walletBalance', props<{ payload: any }>());
export const WALLET_BALANCE_SUCCESS = createAction('[Wallet Balance] walletBalance success', props<{ payload: IWallet }>());
export const WALLET_BALANCE_FAIL = createAction('[Wallet Balance] walletBalance fail', props<{ payload: any }>());
/************* Admin Balance ************************/
export const ADMIN_BALANCE = createAction('[Admin Balance] adminBalance', props<{ payload: any }>());
export const ADMIN_BALANCE_SUCCESS = createAction('[Admin Balance] adminBalance success', props<{ payload: any }>());
export const ADMIN_BALANCE_FAIL = createAction('[Admin Balance] adminBalance fail', props<{ payload: any }>());
export interface ActionWithPayload<T> extends Action { payload: T; }
