import { HttpErrorResponse } from '@angular/common/http';
import { Blog, FAQ } from './../models/media';
import { createAction, props, Action } from "@ngrx/store";

/*============================Blogs================================*/
export const GET_BLOGS = createAction('[Blogs List] getBlogs', props<{ payload: any }>());
export const GET_BLOGS_SUCCESS = createAction('[Blogs List] getBlogs success', props<{ payload: Array<Blog> }>());
export const GET_BLOGS_FAILED = createAction('[Blogs List] getBlogs fail', props<{ payload: any }>());

export const GET_BLOG = createAction('[Blog ] getBlogById', props<{ payload: any }>());
export const GET_BLOG_SUCCESS = createAction('[Blog ] getBlogById success', props<{ payload: Blog }>());
export const GET_BLOG_FAILED = createAction('[Blog ] getBlogById fail', props<{ payload: any }>());

export const CREAT_BLOG = createAction('[Create Blog] createBlog', props<{ payload: Blog }>());
export const CREAT_BLOG_SUCCESS = createAction('[Create Blog] createBlog success', props<{ payload: any }>());
export const CREAT_BLOG_FAILED = createAction('[Create Blog] createBlog fail', props<{ payload: any }>());

export const UPDATE_BLOG = createAction('[Update Blog] updateBlog', props<{ payload: Blog }>());
export const UPDATE_BLOG_SUCCESS = createAction('[Update Blog] updateBlog success', props<{ payload: any }>());
export const UPDATE_BLOG_FAILED = createAction('[Update Blog] updateBlog fail', props<{ payload: any }>());

export const DELETE_BLOG = createAction('[Delete Blog] deleteBlog', props<{ payload: string }>());
export const DELETE_BLOG_SUCCESS = createAction('[Delete Blog] deleteBlog success', props<{ payload: any }>());
export const DELETE_BLOG_FAILED = createAction('[Delete Blog] deleteBlog fail', props<{ payload: any }>());


/*============================FAQs================================*/
export const GET_FAQs = createAction('[FAQs List] getFAQs');
export const GET_FAQs_SUCCESS = createAction('[FAQs List] getFAQs success', props<{ payload: Array<FAQ> }>());
export const GET_FAQs_FAILED = createAction('[FAQs List] getFAQs fail', props<{ payload: Array<any> }>());

export const GET_FAQ = createAction('[FAQ ] getFAQById', props<{ payload: any }>());
export const GET_FAQ_SUCCESS = createAction('[FAQ ] getFAQById success', props<{ payload: FAQ }>());
export const GET_FAQ_FAILED = createAction('[FAQ ] getFAQById fail', props<{ payload: any }>());

export const CREAT_FAQ = createAction('[Create FAQ] creatFAQ', props<{ payload: any }>());
export const CREAT_FAQ_SUCCESS = createAction('[Create FAQ ] creatFAQ success', props<{ payload: any }>());
export const CREAT_FAQ_FAILED = createAction('[Create FAQ ] creatFAQ fail', props<{ payload: HttpErrorResponse }>());

export const UPDATE_FAQ = createAction('[Update FAQ] updateFAQ', props<{ payload: FAQ }>());
export const UPDATE_FAQ_SUCCESS = createAction('[Update FAQ] updateFAQ success', props<{ payload: any }>());
export const UPDATE_FAQ_FAILED = createAction('[Update FAQ] updateFAQ fail', props<{ payload: any }>());

export const DELETE_FAQ = createAction('[Delete FAQ] deleteFAQ', props<{ payload: string }>());
export const DELETE_FAQ_SUCCESS = createAction('[Delete FAQ] deleteFAQ success', props<{ payload: any}>());
export const DELETE_FAQ_FAILED = createAction('[Delete FAQ] deleteFAQ fail', props<{ payload: any }>());

export interface ActionWithPayload<T> extends Action { payload: T; }
