import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as cartActions from '../actions/cart.action';
import { ICart } from '../models/interfaces/cart.model';

export const cartFeatureKey = 'cartModule';
export const adapter: EntityAdapter<ICart> = createEntityAdapter<ICart>({ selectId: selectCategoryId });
export function selectCategoryId(a: ICart): string { return a._id as string; }

export interface FeatureState extends EntityState<ICart> {
  cart: ICart | any;
  message: any;
  updateCart: any;
}
export interface AppState { cartModule: FeatureState; }

export const selectFeature = (state: AppState) => state.cartModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureCarts = createSelector(selectFeature, (state: FeatureState) => state);
export const selectCart = createSelector(selectFeatureCarts, (state: FeatureState) => state.cart);
export const selectUpdateCart = createSelector(selectFeatureCarts, (state: FeatureState) => state.updateCart);
export const initialState: FeatureState = adapter.getInitialState({
  cart: {},
  message: '',
  updateCart: {}
});
const cartReducer = createReducer(
  initialState,
  on(cartActions.GET_CART, (state) => ({ ...state })),
  on(cartActions.GET_CART_FAIL, (state, action) => ({ ...state })),
  on(cartActions.GET_CART_SUCCESS, (state, action) => { return { ...state, cart: action.payload }; }),

  on(cartActions.ADD_TO_CART, (state) => ({ ...state })),
  on(cartActions.ADD_TO_CART_FAIL, (state, action) => ({ ...state,updateCart:action.payload })),
  on(cartActions.ADD_TO_CART_SUCCESS, (state, action) => { return { ...state, updateCart: action.payload }; }),
);
export function reducer(state: FeatureState, action: Action) { return cartReducer(state, action); }

export function selectAllMainCategoriesEntities(selectAllMainCategoriesEntities: any, arg1: (categories: import("../models/category").MainCategory[]) => import("../models/category").MainCategory[]) {
  throw new Error('Function not implemented.');
}
