import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../actions/notification.action';
import { Notification } from '../models/interfaces/notification';
import * as fromFeature from '../reducers/notification.reducer';
@Injectable({ providedIn: 'root' })
export class NotificationStoreProvider {
  constructor(public store: Store<fromFeature.AppState>) {}
  public getAllNotifications$(): Observable<Notification[]> {
    this.store.dispatch(fromActions.GET_ALL_NOTIFICATIONS());
    return this.store.pipe(
      select(fromFeature.selectAllNotifications),
      map((notifications: Notification[]) => notifications)
    );
  }
  public getUserNotifications$(): Observable<Notification[]> {
    this.store.dispatch(fromActions.GET_USER_NOTIFICATIONS());
    return this.store.pipe(
      select(fromFeature.selectUserNotifications),
      map((notifications: Notification[]) => notifications)
    );
  }
  public getNotification$(payload: string): Observable<Notification> {
    this.store.dispatch(fromActions.GET_NOTIFICATION({ payload }));
    return this.store.pipe(select(fromFeature.selectNotification));
  }
  public createNotification$(NotificationData: Notification): Observable<Notification | undefined> {
    this.store.dispatch(fromActions.CREATE_NOTIFICATION({ payload: NotificationData })
    );
    return this.store.pipe(select(fromFeature.selectAddedNotification));
  }
  public updateNotification$(payload: string): Observable<Notification> {
    this.store.dispatch(fromActions.READ_NOTIFICATION({ payload }));
    return this.store.pipe(select(fromFeature.selectNotification));
  }
  public deleteNotification(id: string): void {
    this.store.dispatch(fromActions.DELETE_NOTIFICATION({ payload: id }));
  }
}
