import { CustomPipesModule } from 'projects/shared/modules/custom-pipes.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { NgxPaginationModule } from 'ngx-pagination';
import { AvatarModule } from 'primeng/avatar';
import { ToastModule } from 'primeng/toast';
import { ChipModule } from 'primeng/chip';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScrollTopModule } from 'primeng/scrolltop';

const PrimengComponents = [
  TableModule,
  ButtonModule,
  FileUploadModule,
  AvatarModule,
  ToastModule,
  ChipModule,
  GalleriaModule,
  ProgressBarModule,
  ConfirmDialogModule,
  ScrollTopModule
]
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    CustomPipesModule,
    PaginatorModule,
    PrimengComponents

  ],
  exports: [

    PaginatorModule,
    NgxPaginationModule,
    PrimengComponents
  ],
  providers: [ConfirmationService, MessageService]
})
export class CoreModule { }
