import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './+shared/modules/core.module';

import { CookieService } from 'ngx-cookie-service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromNotificationReducer from '../../../shared/reducers/notification.reducer';
import * as fromUserReducer from '../../../shared/reducers/user.reducer';
import * as fromMainCategoryReducer from '../../../shared/reducers/category/main-category.reducer';
// import * as fromCategoryReducer from '../../../shared/reducers/category/category.reducer';
// import * as fromSubCategoryReducer from '../../../shared/reducers/category/sub-category.reducer';
import * as fromWalletReducer from '../../../shared/reducers/wallet.reducer';
import * as fromProductReducer from '../../../shared/reducers/product.reducer';
import * as fromCartReducer from '../../../shared/reducers/cart.reducer';
import * as fromTicketSystemReducer from '../../../shared/reducers/ticket-system.reducer';
import { CartEffects } from '../../../shared/effects/cart.effect';
import { UserEffects } from '../../../shared/effects/user.effect';
// import { CategoryEffects } from '../../../shared/effects/category.effect';
import { CheckoutEffects } from '../../../shared/effects/checkout.effect';
import { WalletEffects } from 'projects/shared/effects/wallet.effect';
import { ProductEffects } from 'projects/shared/effects/product.effect';
import { NotificationEffects } from 'projects/shared/effects/notification.effect';
import { TicketSystemEffects } from 'projects/shared/effects/ticket-system.effect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageService } from 'primeng/api';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingSpinnerInterceptor } from 'projects/shared/interceptor/loadin-spinner-interceptor';
import { LayoutsModule } from './layout/layouts.module';
import { DialogModule } from 'primeng/dialog';


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [

    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CoreModule,
    LayoutsModule,
    EffectsModule.forFeature([
      UserEffects,
      // CategoryEffects,
      WalletEffects,
      ProductEffects,
      NotificationEffects,
      CartEffects,
      TicketSystemEffects,
      CheckoutEffects,
      // DialogModule
    ]),
    EffectsModule.forRoot(),
    StoreModule.forRoot({}),
    StoreModule.forFeature(
      fromNotificationReducer.notificationFeatureKey,
      fromNotificationReducer.reducer
    ),
    StoreModule.forFeature(
      fromUserReducer.usersFeatureKey,
      fromUserReducer.reducer
    ),
    StoreModule.forFeature(
      fromUserReducer.usersFeatureKey,
      fromUserReducer.reducer
    ),
    // StoreModule.forFeature(
    //   fromMainCategoryReducer.mainCategoriesFeatureKey,
    //   fromMainCategoryReducer.reducer
    // ),
    // StoreModule.forFeature(
    //   fromCategoryReducer.categoriesFeatureKey,
    //   fromCategoryReducer.reducer
    // ),
    // StoreModule.forFeature(
    //   fromSubCategoryReducer.subCategoriesFeatureKey,
    //   fromSubCategoryReducer.reducer
    // ),
    StoreModule.forFeature(
      fromWalletReducer.walletFeatureKey,
      fromWalletReducer.reducer
    ),
    StoreModule.forFeature(
      fromProductReducer.productsFeatureKey,
      fromProductReducer.reducer
    ),
    StoreModule.forFeature(
      fromCartReducer.cartFeatureKey,
      fromCartReducer.reducer
    ),
    StoreModule.forFeature(
      fromTicketSystemReducer.ticketSystemFeatureKey,
      fromTicketSystemReducer.reducer
    ),
  ],
  providers: [CookieService, MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
