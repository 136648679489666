import { Roles } from '../enums/roles.enum';
import { VerificationStatus } from './verifications';

export interface IUser {
  _id: string;
  name: string;
  password: string;
  email: string;
  phone: string;
  token: string;
  is2FAEnabled?: string
  verificationData: { images: []; idType: string };
  isAdmin: true;
  walletBalance: Number;
  country: string;
  countryFlag: string;
  currency: string;
  rankPoints: number;
  rank: any;
  accountStatus: string;
  roles: Roles;
  avatar: string;
  ipAddress?: string;
  verifiedEmail: boolean;
  userVerification: VerificationStatus;
  paymentVerification: string;
  resetToken: string;
  banStatus: BanStatus;
  notes: [];
  twoFactorAuth: boolean;
  twoFactorSecret: string;
  emailToken: string;
  passwordCheck?: boolean;
  createdAt?: string;
  message?: string;
  lastLogin?: string
}
export enum BanStatus {
  normal = 'NORMAL',
  freezed = 'FREEZED',
  blocked = 'BLOCKED',
}
export interface IChangePassword {
  currentPassword: string;
  password: string;
}
export interface TwoFAVerified {
  message: string;
  user: IUser;
}
export interface ILoginSecret {
  message: string;
  loginSecret: string;
}

export interface IUserLogin {
  email: string;
  password: string;
}

export interface ISignInResponse {
  message?: string;
  token?: string;
  loginSecret?: string;
  is2FAEnabled?: boolean;
  hash: {
    expiryDate: string,
    value: string
  }
}

export interface IUserRegister {
  name: string;
  email: string;
  password: string;
  phone: string;
  currency: string;
}

export interface IForgetPassword {
  email: string;
}

export interface IForgetPasswordResponse {
  email: string;
  password: string;
}
export interface Email {
  _id: string
  email: string
}
export enum Rank {
  normal = 'Normal',
  silver = 'Silver',
  gold = 'Gold',
  diamond = 'Diamond',
}
export enum AccountStatus {
  normal = 'NORMAL',
  reseller = 'RESELLER',
}
