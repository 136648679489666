import { Observable, catchError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { TicketCountByStatus, TicketReplay, TicketSystem, } from '../models/interfaces/tieckets-system';
import { IResponse } from '../models/interfaces/response.model';
@Injectable({
  providedIn: 'root',
})
export class TicketSystemService {

  url = env.apiUrl + 'tickets';
  constructor(private http: HttpClient) { }
  createTickets(ticket: TicketSystem): Observable<TicketSystem> {
    return this.http.post<TicketSystem>(`${this.url}`, ticket, {
      withCredentials: true,
    });
  }
  // for admin
  getTickets(param: {} = {}): Observable<IResponse<TicketSystem>> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<IResponse<TicketSystem>>(`${this.url}?${params}`, {
      withCredentials: true,
    });
  }
  getTicket(id: any): Observable<TicketSystem> {
    return this.http.get<TicketSystem>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }
  // for user
  userTickets(param: {} = {}): Observable<IResponse<TicketSystem>> {
    const params = new HttpParams({ fromObject: param })
    return this.http.get<IResponse<TicketSystem>>(
      `${this.url}/user-tickets?${params}`,
      { withCredentials: true }
    );
  }
  userTicket(id: string): Observable<TicketSystem> {
    return this.http
      .get<TicketSystem>(`${this.url}/user-tickets/${id}`, {
        withCredentials: true,
      })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  // admin and user
  deleteTicket(id: any): Observable<TicketSystem> {
    return this.http.delete<TicketSystem>(`${this.url}/${id}`, {
      withCredentials: true,
    });
  }
  closeTicket(ticketId: string): Observable<TicketSystem> {
    return this.http.patch<TicketSystem>(
      `${this.url}/close-ticket/${ticketId}`,
      {},
      { withCredentials: true }
    );
  }

  replyTicket(ticketId: string, replay: any): Observable<TicketReplay> {
    return this.http.post<TicketReplay>(
      `${this.url}/admin/add-ticket-reply/${ticketId}`,
      replay,
      { withCredentials: true }
    );
  }

  getTicketReplies(ticketId: string, query = ''): Observable<TicketReplay[]> {
    return this.http.get<TicketReplay[]>(
      `${this.url}/ticket-replies/${ticketId}${query}`,
      { withCredentials: true }
    );
  }

  updateTicketReply(replayId: string, newReplay: TicketReplay): Observable<TicketReplay> {
    return this.http.patch<TicketReplay>(
      `${this.url}//update-ticket-reply//${replayId}`,
      newReplay,
      { withCredentials: true }
    );
  }

  changeTicketStatus(id: any, statusQuery = ''): Observable<TicketSystem> {
    return this.http.patch<TicketSystem>(
      `${this.url}/change-ticket-status/${id}${statusQuery}`,
      { withCredentials: true }
    );
  }

  getCountByStatus(): Observable<TicketCountByStatus> {
    return this.http.get<TicketCountByStatus>(`${this.url}/count-by-status`, { withCredentials: true });
  }
}
