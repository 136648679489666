import { Observable, Subscription } from 'rxjs';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { MainCategory, MainCategory1 } from 'projects/shared/models/category';
import { MainCategoryStoreProvider } from 'projects/shared/providers/category/main-category.provider';
// import { selectAllMainCategoriesEntities, } from 'projects/shared/reducers/category/main-category.reducer';
// import * as fromFeature from 'projects/shared/reducers/category/main-category.reducer';
import { Store } from '@ngrx/store';
import { CategoryService } from 'projects/shared/services/category.service';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';


@Component({
  selector: 'app-navbar-links',
  templateUrl: './navbar-links.component.html',
  styleUrls: ['./navbar-links.component.scss']
})
export class NavbarLinksComponent implements OnInit, OnDestroy {

  mainCategoryList?: MainCategory1[];
  subscription: Subscription = new Subscription()
  mainCategories$: Observable<MainCategory[]> | any;

  constructor(private mainCategoryStore: MainCategoryStoreProvider, private cdr: ChangeDetectorRef, private categoryService: CategoryService, private storeChangesServ: StoreChangesService,
  ) { }

  ngOnInit(): void {
    this.getAllMainCategories()
  }
  // getAllMainCategories() {

  //   console.log('in navbar links')
  //   if (localStorage.getItem("mainCategoryList")) {
  //     let retString: any = localStorage.getItem("mainCategoryList")
  //     let retArray = JSON.parse(retString)
  //     this.mainCategoryList = retArray
  //   } else {
  //   const query = "?sort=order";
  //   this.categoryService.getMainCategories(query).subscribe((response) => {
  //     this.mainCategoryList = response.data;
  //     // if (this.mainCategoryList !== undefined) {

  //     //   this.storeChangesServ.setCategoryFromParent(this.mainCategoryList)
  //     // }
  //     // console.log(this.mainCategoryList)
  //   });
  // }

  // }
  getAllMainCategories() {
    console.log('in navbar links')
    if (localStorage.getItem("mainCategoryList")) {
      let retString: any = localStorage.getItem("mainCategoryList")
      let retArray = JSON.parse(retString)
      this.mainCategoryList = retArray
    } else {
      const query = "?sort=order";
      this.categoryService.getUserMainCategories(query).subscribe((response) => {
        this.mainCategoryList = response.data;
        console.log(this.mainCategoryList)
      });
    }
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

}
