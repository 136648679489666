import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavFooterLayoutComponent } from './layout/components/nav-footer-layout/nav-footer-layout.component';
import { FullContentLayoutComponent } from './layout/components/full-content-layout/full-content-layout.component';
import { NotFoundComponent } from './layout/not-found/components/not-found/not-found.component';
import { CheckoutGuard } from 'projects/shared/guards/checkout-guard';

const routes: Routes = [
  {
    path: '',
    component: NavFooterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/user-settings/user-settings.module').then(
            (m) => m.UserSettingsModule
          ),
      },
      {
        path: 'quick-links',
        loadChildren: () =>
          import('./components/footer/footer.module').then(
            (m) => m.FooterModule
          ),
      },
      {
        path: 'categories',
        loadChildren: () =>
          import('./modules/categories/categories.module').then(
            (m) => m.CategoriesModule
          ),
      },
      {
        path: 'checkout',
        canActivate: [CheckoutGuard],
        loadChildren: () =>
          import('./modules/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('./components/footer/blogs/blogs.module').then(
            (m) => m.BlogsModule
          ),
      },
      { path: '404', component: NotFoundComponent },
    ],
  },
  {
    path: 'auth',
    component: FullContentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import(`./modules/auth/auth.module`).then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,

    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
