import { SendNote } from './../models/note';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../actions/user.action';
import * as fromFeature from '../reducers/user.reducer';
import { IForgetPassword, ISignInResponse, IUser, IUserLogin, IUserRegister } from '../models/interfaces/user.model';
import { IResponse } from '../models/interfaces/response.model';
@Injectable({
  providedIn: 'root'
})

export class UserStoreProvider {
  constructor(public store: Store<fromFeature.AppState>) { }

  public getAllUsers$(querParam = ''): Observable<IResponse<IUser>> {
    this.store.dispatch(fromActions.GET_ALL_USERS({ payload: querParam }));
    return this.store.pipe(select(fromFeature.selectUsers), map((users: IResponse<IUser>) => users));
  }
  public signUp$(newData: IUserRegister): Observable<IUser | any> {
    this.store.dispatch(fromActions.SIGNUP({ payload: newData }));
    return this.store.pipe(select(fromFeature.selectUser));
  }
  public signIn(loginData: IUserLogin): Observable<ISignInResponse | any> {
    this.store.dispatch(fromActions.SIGNIN({ payload: loginData }));
    return this.store.pipe(select(fromFeature.selectSignIn));
  }
  public changePassword$(changePassword: any): Observable<IUser> {
    this.store.dispatch(fromActions.CHANGE_PASSWORD({ payload: changePassword }));
    return this.store.pipe(select(fromFeature.selectChangePassword));
  }
  public setFA$(queryParam = ''): Observable<any> {
    this.store.dispatch(fromActions.SET2FA({ payload: queryParam }));
    return this.store.pipe(select(fromFeature.select2FAImage));
  }
  public activate2FA$(token: string): Observable<any> {
    this.store.dispatch(fromActions.ACTIVATE_2FA({ payload: token }));
    return this.store.pipe(select(fromFeature.select2FAActivated));
  }
  public vefiy2FA$(payload: any): Observable<any> {
    this.store.dispatch(fromActions.VERIFY_2FA({ payload }));
    return this.store.pipe(select(fromFeature.select2FAVerified));
  }
  public vefiyEmail$(token: any): Observable<any> {
    this.store.dispatch(fromActions.VERIFY_EMAIL({ payload: token }));
    return this.store.pipe(select(fromFeature.selectVerfiy));
  }
  public vefiyID$(images: any): Observable<any> {
    this.store.dispatch(fromActions.VERIFY_ID({ payload: images }));
    return this.store.pipe(select(fromFeature.selectUser));
  }
  public forgetPassword$(email: IForgetPassword): Observable<any> {
    this.store.dispatch(fromActions.FORGET_PASSWORD({ payload: email }));
    return this.store.pipe(select(fromFeature.selectVerfiy));
  }
  public resetPassword$(token: any, newPassword: any): Observable<IUser | any> {
    this.store.dispatch(fromActions.RESET_PASSWORD({ payload: { token: token, password: newPassword } }));
    return this.store.pipe(select(fromFeature.selectUser));
  }
  public resendEmail$(email: any): Observable<any> {
    this.store.dispatch(fromActions.RESEND_EMAIL({ payload: email }));
    return this.store.pipe(select(fromFeature.selectVerfiy));
  }
  public validate$(): Observable<IUser | null> {
    this.store.dispatch(fromActions.VALIDATE());
    return this.store.pipe(select(fromFeature.selectUser));
  }
  public logout$() {
    this.store.dispatch(fromActions.LOGOUT());
    return this.store.pipe(select(fromFeature.selectLogout));
  }

  public updateUser$(registerData: IUser): Observable<IUser | null> {
    this.store.dispatch(fromActions.UPDATE_USER({ payload: registerData }));
    return this.store.pipe(select(fromFeature.selectUpdatedUser));
  }
  public addUserNote$(note: SendNote): Observable<IUser | any> {
    this.store.dispatch(fromActions.ADD_USER_NOTE({ payload: note }));
    return this.store.pipe(select(fromFeature.selectUser));
  }

}
