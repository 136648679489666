import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  retry,
  tap,
} from 'rxjs/operators';
import * as checkoutActions from '../actions/checkout.action';
import {
  CheckoutService,
} from '../services/checkout.service';

@Injectable()
export class CheckoutEffects {
  constructor(
    private actions$: Actions,
    private checkoutService: CheckoutService,
  ) {}

  public checkout$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutActions.CHECKOUT.type),
    mergeMap((action: checkoutActions.ActionWithPayload<any>) => {
      return this.checkoutService.checkout(action.payload).pipe(
        retry(1),
        map((response) => {
          return checkoutActions.CHECKOUT_SUCCESS({
            payload: response,
          });
        }),
        catchError((error) => of(
          checkoutActions.CHECKOUT_FAIL({ payload: error })),
        ),
      );
    }),
  ));

  public errorOnCheckout$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutActions.CHECKOUT_FAIL.type),
    tap(
        (action: checkoutActions.ActionWithPayload<any>) => {
          return action.payload;
        },
      ),
    ),
    { dispatch: false },
  );

  public completeOrder$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutActions.COMPLETE_ORDER.type),
    mergeMap((action: checkoutActions.ActionWithPayload<string>) => {
      return this.checkoutService.completeOrder(action.payload).pipe(
        retry(1),
        map((response) => {
          return checkoutActions.COMPLETE_ORDER_SUCCESS({
            payload: response,
          });
        }),
        catchError(
          (error) => of(
            checkoutActions.COMPLETE_ORDER_FAIL({ payload: error }),
          ),
        ),
      );
    }),
  ));

  public returnOrder$ = createEffect(() => this.actions$.pipe(
    ofType(checkoutActions.RETURN_ORDER.type),
    mergeMap((action: checkoutActions.ActionWithPayload<string>) => {
      return this.checkoutService.returnOrder(action.payload).pipe(
        retry(1),
        map((response) => {
          return checkoutActions.RETURN_ORDER_SUCCESS({
            payload: response,
          });
        }),
        catchError(
          (error) => of(
            checkoutActions.RETURN_ORDER_FAIL({ payload: error }),
          ),
        ),
      );
    }),
  ));
}
