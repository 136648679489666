<section class="loading-logo" [class.hide]="!showLoading">
  <div>
    <img src="../assets/images/main/white 1.png" alt="">

    <div class="loading">
      <span class="loading__dot"></span>
      <span class="loading__dot"></span>
      <span class="loading__dot"></span>
    </div>
  </div>
  <div class="circles ">
    <span class="circle-1 position-absolute">
    </span>
    <span class="circle-2 position-absolute">
    </span>
  </div>
</section>
<router-outlet *ngIf="!showLoading">
</router-outlet>
