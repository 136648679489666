import { AccordionModule } from 'primeng/accordion';
import { CustomPipesModule } from '../../../../../shared/modules/custom-pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { BlogEffects } from './../../../../../shared/effects/media/blog.effect';
import * as fromBlogReducer from '../../../../../shared/reducers/media/blogs.reducer';
import * as fromFAQReducer from '../../../../../shared/reducers/media/faq.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FAQEffects } from '../../../../../shared/effects/media/FAQ.effect';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutUsComponent } from './about-us/about-us.component';
import { ComeResellerComponent } from './come-reseller/come-reseller.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FAQComponent } from './faq/faq.component';
import { FooterRoutingModule } from './footer-routing.module';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { SharedModule } from 'projects/shared/modules/shared.module';

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    FAQComponent,
    FooterComponent,
    ContactsComponent,
    AboutUsComponent,
    RefundPolicyComponent,
    TermsConditionComponent,
    ComeResellerComponent,

  ],
  imports: [
    CommonModule,
    FooterRoutingModule,
    InputTextModule,
    InputTextareaModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipesModule,
    SharedModule,
    EffectsModule.forFeature([BlogEffects, FAQEffects]),

    StoreModule.forFeature(
      fromBlogReducer.blogFeatureKey,
      fromBlogReducer.reducer
    ),
    StoreModule.forFeature(
      fromFAQReducer.FAQFeatureKey,
      fromFAQReducer.reducer
    ),
    AccordionModule,

  ],
  exports: [
    FooterComponent,
    FAQComponent
  ]
})
export class FooterModule { }
