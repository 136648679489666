import { SendNote } from './../models/note';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, createAction, props } from "@ngrx/store";
import { IForgetPassword, IUserRegister } from '../models/interfaces/user.model';
/************* get all users ************************/
export const GET_ALL_USERS = createAction('[User List] getAllUsers', props<{ payload: string }>());
export const GET_ALL_USERS_SUCCESS = createAction('[User List] getAllUsers success', props<{ payload: any }>());
export const GET_ALL_USERS_FAIL = createAction('[User List] getAllUsers fail', props<{ payload: any }>());

/*************  Register ************************/
export const SIGNUP = createAction('[Create User] signup', props<{ payload: IUserRegister }>());
export const SIGNUP_SUCCESS = createAction('[Create User] signup Success', props<{ payload: any }>());
export const SIGNUP_FAIL = createAction('[Create User]signup Fail', props<{ payload: any }>());
/*************  Login ************************/
export const SIGNIN = createAction('[Login] signin', props<{ payload: any }>());
export const SIGNIN_SUCCESS = createAction('[Login] signin Success', props<{ payload: any }>());
export const SIGNIN_FAIL = createAction('[Login] signin Fail', props<{ payload: any }>());
/*************  Forget password ************************/
export const FORGET_PASSWORD = createAction('[Password] forgetPassword', props<{ payload: IForgetPassword }>());
export const FORGET_PASSWORD_SUCCESS = createAction('[Password] forgetPassword Success', props<{ payload: any }>());
export const FORGET_PASSWORD_FAIL = createAction('[Password] forgetPassword Fail', props<{ payload: any }>());
/*************  RESET password ************************/
export const RESET_PASSWORD = createAction('[Password] resetPassword', props<{ payload: any }>());
export const RESET_PASSWORD_SUCCESS = createAction('[Password] resetPassword Success', props<{ payload: any }>());
export const RESET_PASSWORD_FAIL = createAction('[Password] resetPassword Fail', props<{ payload: any }>());
/*************  Logout ************************/
export const LOGOUT = createAction('[logout] logout');
export const LOGOUT_SUCCESS = createAction('[logout] logout Success');
export const LOGOUT_FAIL = createAction('[logout] logout Fail');


/*************  update user by Id ************************/
export const UPDATE_USER = createAction('[Update User] updateUser', props<{ payload: any }>());
export const UPDATE_USER_SUCCESS = createAction('[Update any] updateUser Success', props<{ payload: any }>());
export const UPDATE_USER_FAIL = createAction('[Update User] updateUser Fail', props<{ payload: any }>());

/*************  delete user by Id ************************/
export const DELETE_USER = createAction('[Delete User] deleteUser', props<{ payload: string }>());
export const DELETE_USER_SUCCESS = createAction('[Delete User] deleteUser Success', props<{ payload: any }>());
export const DELETE_USER_FAIL = createAction('[Delete User] deleteUser Fail', props<{ payload: any }>());

/*************  Verify ID ************************/
export const VERIFY_EMAIL = createAction('[Verify Email] verifyEmail', props<{ payload: string }>());
export const VERIFY_EMAIL_SUCCESS = createAction('[Verify Email] verifyEmail Success', props<{ payload: any }>());
export const VERIFY_EMAIL_FAIL = createAction('[Verify Email] verifyEmail Fail', props<{ payload: any }>());

/*************  Verify id ************************/
export const VERIFY_ID = createAction('[Verfiy ID] verifyID', props<{ payload: string }>());
export const VERIFY_ID_SUCCESS = createAction('[Verify ID] verifyID Success', props<{ payload: any }>());
export const VERIFY_ID_FAIL = createAction('[Verify ID] verifyID Fail', props<{ payload: any }>());

/*************  Set 2FA ************************/
export const ACTIVATE_2FA = createAction('[Activate 2fa] activate2FA', props<{ payload: string }>());
export const ACTIVATE_2FA_SUCCESS = createAction('[Activate 2fa] activate2FA Success', props<{ payload: any }>());
export const ACTIVATE_2FA_FAIL = createAction('[Activate 2fa] activate2FA Fail', props<{ payload: any }>());
/*************  Activate 2FA ************************/
export const SET2FA = createAction('[Set 2fa] set2FA', props<{ payload: string }>());
export const SET2FA_SUCCESS = createAction('[Set 2fa] set2FA Success', props<{ payload: any }>());
export const SET2FA_FAIL = createAction('[Set 2fa] set2FA Fail', props<{ payload: any }>());

/************* Veify 2FA ************************/
export const VERIFY_2FA = createAction('[Set 2fa] verify2FA', props<{ payload: any }>());
export const VERIFY_2FA_SUCCESS = createAction('[Set 2fa] verify2FA Success', props<{ payload: any }>());
export const VERIFY_2FA_FAIL = createAction('[Set 2fa] verify2FA Fail', props<{ payload: any }>());

/*************  update user by Id ************************/
export const CHANGE_PASSWORD = createAction('[Password] changePassword', props<{ payload: any }>());
export const CHANGE_PASSWORD_SUCCESS = createAction('[Password] changePassword Success', props<{ payload: any }>());
export const CHANGE_PASSWORD_FAIL = createAction('[Password] changePassword Fail', props<{ payload: HttpErrorResponse }>());
/************* Resend Email ************************/
export const RESEND_EMAIL = createAction('[Resend Email] resendEmail', props<{ payload: any }>());
export const RESEND_EMAIL_SUCCESS = createAction('[Resend Email] resendEmail Success', props<{ payload: any }>());
export const RESEND_EMAIL_FAIL = createAction('[Resend Email] resendEmail Fail', props<{ payload: any }>());
/************* Validaate ************************/
export const VALIDATE = createAction('[Validate] validate');
export const VALIDATE_SUCCESS = createAction('[Validate] validate Success', props<{ payload: any }>());
export const VALIDATE_FAIL = createAction('[Validate] validate Fail', props<{ payload: HttpErrorResponse }>());

export const ADD_USER_NOTE = createAction('[Create User Note] createUserNote ', props<{ payload: SendNote }>());
export const ADD_USER_NOTE_SUCCESS = createAction('[Create User Note] createUserNote Success', props<{ payload: any }>());
export const ADD_USER_NOTE_FAIL = createAction('[Create User Note] createUserNote Fail', props<{ payload: any }>());

export interface ActionWithPayload<T> extends Action { payload: T; }
