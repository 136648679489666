import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar-main',
  templateUrl: './navbar-main.component.html',
  styleUrls: ['./navbar-main.component.scss']
})
export class NavbarMainComponent implements OnInit {
  screenWidth: any;

  isNavbarFixed: boolean = false;


  constructor() {
    this.getScreenSize()
  }


  ngOnInit(): void {
  }
  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.scrollY > 100) {
      this.isNavbarFixed = true;
    } else {
      this.isNavbarFixed = false;
    }
  }


  @HostListener('window:resize', ['$event'])

  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

}
