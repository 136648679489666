import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector, createReducer, on, Action } from '@ngrx/store';
import { Blog, BlogResponse } from './../../models/media';
import * as blogActions from '../../actions/media.action'

export const blogFeatureKey = 'blogModule';
export const adapter: EntityAdapter<Blog> = createEntityAdapter<Blog>({ selectId: selectCategoryId });
export function selectCategoryId(a: Blog): string { return a._id as string; }

export interface FeatureState extends EntityState<Blog> {
  blog: BlogResponse;
  getBlog: Blog;
}
export interface AppState { blogModule: FeatureState; }

export const selectFeature = (state: AppState) => state.blogModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureBlog = createSelector(selectFeature, (state: FeatureState) => state);
export const selectAllBlogsEntities = createSelector(selectFeatureBlog, selectAll);
export const selectCreateBlog = createSelector(selectFeatureBlog, (state: FeatureState) => state.blog);
export const selectBlogById = createSelector(selectFeatureBlog, (state: FeatureState) => state.getBlog);

export const initialState: FeatureState = adapter.getInitialState({
  blog: {},
  getBlog: {}
});
const blogReducer = createReducer(
  initialState,
  on(blogActions.GET_BLOGS, (state) => { return adapter.removeAll(state); }),
  on(blogActions.GET_BLOGS_FAILED, (state, action) => { return { ...state, } }),
  on(blogActions.GET_BLOGS_SUCCESS, (state, action) => { return adapter.addMany(action.payload, state); }),

  on(blogActions.GET_BLOG, (state) => ({ ...state })),
  on(blogActions.GET_BLOG_FAILED, (state, action) => ({ ...state })),
  on(blogActions.GET_BLOG_SUCCESS, (state, action) => { return { ...state, getBlog: action.payload } }),

  on(blogActions.CREAT_BLOG, (state) => ({ ...state })),
  on(blogActions.CREAT_BLOG_FAILED, (state, action) => { return { ...state, blog: action.payload } }),
  on(blogActions.CREAT_BLOG_SUCCESS, (state, action) => { return { ...state, blog: action.payload } }),

  on(blogActions.UPDATE_BLOG, (state) => ({ ...state })),
  on(blogActions.UPDATE_BLOG_FAILED, (state, action) => { return { ...state, blog: action.payload } }),
  on(blogActions.UPDATE_BLOG_SUCCESS, (state, action) => { return { ...state, blog: action.payload } }),

  on(blogActions.DELETE_BLOG, (state) => ({ ...state })),
  on(blogActions.DELETE_BLOG_FAILED, (state, action) => ({ ...state })),
  on(blogActions.DELETE_BLOG_SUCCESS, (state, action) => { return adapter.removeOne(action.payload._id, state); }),
);
export function reducer(state: FeatureState, action: Action) { return blogReducer(state, action); }
