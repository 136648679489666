import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingSpinnerService } from '../services/loading-spinner.service';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private loadingSpinnerService: LoadingSpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingSpinnerService.show();

    return next.handle(request).pipe(
      finalize(() => {
        this.loadingSpinnerService.hide();
      })
    );
  }
}