import { AboutUsComponent } from './about-us/about-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FAQComponent } from './faq/faq.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ComeResellerComponent } from './come-reseller/come-reseller.component';
import { NavFooterLayoutComponent } from '../../layout/components/nav-footer-layout/nav-footer-layout.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: NavFooterLayoutComponent,
  //   children: [

  //   ]
  // },

  { path: 'about', component: AboutUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'terms-condition', component: TermsConditionComponent },
  { path: 'FAQ', component: FAQComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'reseller', component: ComeResellerComponent },
  {
    path: 'blogs', loadChildren: () => import('./blogs/blogs.module')
      .then(m => m.BlogsModule),
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FooterRoutingModule { }
