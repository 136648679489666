import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ContactUsService } from 'projects/shared/services/contact-us.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  contactForm!: FormGroup;
  loading = false;
  constructor(private _FormBuilder: FormBuilder, private contactUs: ContactUsService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.initContactForm();
  }

  initContactForm() {
    this.contactForm = this._FormBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      website: [''],
      message: ['', Validators.required]
    })
  }

  onSubmit() {
    this.loading = true
    console.log(this.contactForm.value)
    let messageDeatails: any = {
      fullName: this.contactForm.get('fullName')?.value,
      email: this.contactForm.get('email')?.value,
      message: this.contactForm.get('message')?.value
    }
    if (this.contactForm.get('phone')?.value !== '') {
      messageDeatails.phone = this.contactForm.get('phone')?.value
    }
    if (this.contactForm.get('website')?.value !== '') {
      messageDeatails.website = this.contactForm.get('website')?.value
    }
    this.contactUs.creatContactUs(messageDeatails).subscribe(response => {
      this.loading = false
      this.contactForm.reset()
      this.messageService.add(
        { severity: 'success', summary: 'Success', detail: 'You send Successfully' }
      )
    }, (error => {
      this.loading = false
      this.messageService.add(
        { severity: 'error', summary: 'Error', detail: error.error.message }
      )
    }))
  }

  restrictNumeric(e: any) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }
  getControl(controlName: string): any {
    return this.contactForm.get(controlName);
  }

}
