<section class="refund-policy">
  <div class="container position-relative">
    <!-- <h2 class="title-footer">Refund Policy</h2> -->
    <div class="policy-section">
      <h1>Refund Policy</h1>
      <p>Here is the refund policy for card4games Store:</p>
      <ul>
        <li>Once you have ordered and we sent the code for you, we cannot cancel, issue a refund, or exchange the
          product. However, we guarantee that all codes are fresh and working without any problems.</li>
        <li>If you face any problems when redeeming the codes, we will help you to redeem them or exchange them or issue a full refund for you.</li>
        <li>All codes are region-restricted, so you must choose the correct region and enter the correct information.
        </li>
        <li>Some codes have an expiration date.</li>
        <li>If you have any problem with the code, please contact us within the first 24 hours so we can check it with
          our supplier.</li>
        <li>If you have any problems, we are happy to help you 24/7. You can chat with us through our channels:</li>
        <div class="contact-info">
          <p>Facebook page: <a href="https://www.facebook.com/card4GamesLTD"
              target="_blank">https://www.facebook.com/card4GamesLTD</a></p>
          <p>Email: <a href="mailto:sales@card4games.com">sales@card4games.com</a></p>
          <p>WhatsApp: +966552033409</p>
        </div>
      </ul>
      <hr>
      <h2 style="direction: rtl;">سياسة الاسترجاع / رد الأموال</h2>
      <ul style="direction: rtl;">
        <li>بمجرد ان تكمل طلبك ونحن ارسلنا لك الكود لا يمكن الغاء الطلب او طلب رد الاموال او الاستبدال ولكن نحن نضمن لك
          ان الكود سليم ويعمل بشكل طبيعي واذا واجهتك مشكله نحن نتكفل ان نحلها او نستبدل لك الكود فى هذه الحاله.</li>
        <li>بعض الاكواد لها مده انتهاء.</li>
        <li>الاكواد لها دول محدده ولابد ان تختار دولتلك بشكل صحيح والا لن يعمل معك الكود.</li>
        <li>يتم ارسال الطلب لك عندما تكون العمليه صحيحه وغير مشكوك بها وفي حالك الشك فى عمليه الشراء يتم ايقافها فى
          الحال وارجاع كامل المبلغ مره اخري واتخاذ جميع الاجراءات الصحيحه من طرفنا وارسال المعلومات الي قسم الجرائم
          الالكترونيه فى وزاره التجاره لاستكمال التحقيق واتخاذ الازم.</li>
        <li>نحن غير مسوئلين اذا تم ادخال معلومات عن طرفك بشكل خاطئ وقد تؤثر علي طلبك.</li>
        <li>اذا واجهتك مشكله فى الكود لابد ان تقوم بالتبلغ عن ذلك خلال اول 24 ساعه من استلام الطلب كي نقوم بالتواصل مع
          المورد وحل المشكله.</li>
        <li>اذا واجهتك مشكله برجاء التحدث معنا على هذه القنوات:</li>
        <div class="contact-info">
          <p>صفحه الفيس بوك: <a href="https://www.facebook.com/card4GamesLTD"
              target="_blank">https://www.facebook.com/Card4GamesLTD</a></p>
          <p>واتساب: 00201019228625</p>
          <p>الايميل: <a href="mailto:sales@card4games.com">sales@card4games.com</a></p>
        </div>
      </ul>
    </div>
  </div>
</section>
