import { FAQResponse, FAQ } from './../../models/media';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../../actions/media.action';
import * as fromFeature from '../../reducers/media/faq.reducer';

@Injectable({ providedIn: 'root' })
export class FAQstoreProvider {
  constructor(public store: Store<fromFeature.AppState>) { }

  public getAllFAQs$(): Observable<FAQ[]> {
    this.store.dispatch(fromActions.GET_FAQs());
    return this.store.pipe(select(fromFeature.selectAllFAQEntities), map((FAQs: FAQ[]) => FAQs));
  }
  public getFAQ$(faqID:string): Observable<FAQ> {
    this.store.dispatch(fromActions.GET_FAQ({ payload: faqID }));
    return this.store.pipe(select(fromFeature.selectFAQById), map((FAQs: FAQ) => FAQs));
  }
  public createFAQ$(FAQ: FAQ): Observable<FAQResponse> {
    this.store.dispatch(fromActions.CREAT_FAQ({ payload: FAQ }));
    return this.store.pipe(select(fromFeature.selectFAQ), map((FAQ: FAQResponse) => FAQ));
  }
  public updateFAQ$(FAQ: FAQ): Observable<FAQResponse> {
    this.store.dispatch(fromActions.UPDATE_FAQ({ payload: FAQ }));
    return this.store.pipe(select(fromFeature.selectFAQ), map((FAQ: FAQResponse) => FAQ));
  }
  public deleteFAQ$(FAQId: string): Observable<FAQResponse> {
    this.store.dispatch(fromActions.DELETE_FAQ({ payload: FAQId }));
    return this.store.pipe(select(fromFeature.selectFAQ), map((FAQ: FAQResponse) => FAQ));
  }
}
