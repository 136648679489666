import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as ticketSystemActions from '../actions/ticket-system.action';
import { TicketSystem } from '../models/interfaces/tieckets-system';
export const ticketSystemFeatureKey = 'ticketSystemModule';
export const adapter: EntityAdapter<TicketSystem> =
  createEntityAdapter<TicketSystem>({ selectId: selectTicketSystemId });
export function selectTicketSystemId(a: TicketSystem): string {
  return a?._id as string;
}
export interface FeatureState extends EntityState<TicketSystem> {
  ticket: any;
  createdTicket: any;
  updateTicket: any;
  updateSucceeded: boolean;
  message: any;
}
export interface AppState {
  ticketSystemModule: FeatureState;
}
export const selectFeature = (state: AppState) => state.ticketSystemModule;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();
export const selectFeatureTickets = createSelector(
  selectFeature,
  (state: FeatureState) => state
);
export const selectAllUserTicketsEntities = createSelector(
  selectFeatureTickets,
  selectAll
);
export const selectAllUserTickets = createSelector(
  selectFeatureTickets,
  selectEntities
);
export const selectTicket = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.ticket
);
export const selectUserTicket = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.ticket
);
export const selectCreateTicket = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.createdTicket
);
export const selectCloseTicket = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.updateTicket
);
export const selectReplyTicket = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.updateTicket
);
export const selectUpdateTicketStatus = createSelector(
  selectFeatureTickets,
  (state: FeatureState) => state.updateTicket
);
export const initialState: FeatureState = adapter.getInitialState({
  ticket: {},
  createdTicket: {},
  updateTicket: {},
  updateSucceeded: false,
  message: {},
});
let ticketId: string;
const ticketReducer = createReducer(
  initialState,
  on(ticketSystemActions.GET_ALL_USER_TICKETS, (state) => {
    return adapter.removeAll(state);
  }),
  on(ticketSystemActions.GET_ALL_USER_TICKETS_FAIL, (state) => ({ ...state })),
  on(ticketSystemActions.GET_ALL_USER_TICKETS_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload.data, state);
  }),
  on(ticketSystemActions.GET_TICKET_BY_ID, (state) => ({
    ...state,
    ticket: {},
  })),
  on(ticketSystemActions.GET_TICKET_BY_ID_FAIL, (state, action) => {
    return { ...state, ticket: action.payload };
  }),
  on(ticketSystemActions.GET_TICKET_BY_ID_SUCCESS, (state, action) => {
    return adapter.addOne(action.payload, { ...state, ticket: action.payload });
  }),
  on(ticketSystemActions.GET_USER_TICKET_BY_ID, (state) => ({
    ...state,
    ticket: {},
  })),
  on(ticketSystemActions.GET_USER_TICKET_BY_ID_FAIL, (state, action) => {
    return { ...state, ticket: action.payload };
  }),
  on(ticketSystemActions.GET_USER_TICKET_BY_ID_SUCCESS, (state, action) => {
    return adapter.addOne(action.payload, { ...state, ticket: action.payload });
  }),
  on(ticketSystemActions.CREATE_TICKETS, (state) => ({
    ...state,
    createdTicket: {},
  })),
  on(ticketSystemActions.CREATE_TICKETS_FAIL, (state, action) => {
    return { ...state, createdTicket: action.payload };
  }),
  on(ticketSystemActions.CREATE_TICKETS_SUCCESS, (state, action) => {
    return adapter.addOne(action.payload, {
      ...state,
      createdTicket: action.payload,
    });
  }),
  on(ticketSystemActions.CLOSE_TICKET_BY_ID, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.CLOSE_TICKET_BY_ID_FAIL, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.CLOSE_TICKET_BY_ID_SUCCESS, (state, action) => {
    return { ...state, updateTicket: action.payload, updateSucceeded: true };
  }),
  on(ticketSystemActions.REPLY_TICKET, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.REPLY_TICKET_FAIL, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.REPLY_TICKET_SUCCESS, (state, action) => {
    return { ...state, updateTicket: action.payload, updateSucceeded: true };
  }),
  on(ticketSystemActions.UPDATE_TICKET_STATUS, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.UPDATE_TICKET_STATUS_FAIL, (state) => ({
    ...state,
    updateTicket: {},
    updateSucceeded: false,
  })),
  on(ticketSystemActions.UPDATE_TICKET_STATUS_SUCCESS, (state, action) => {
    return { ...state, updateTicket: action.payload, updateSucceeded: true };
  }),
  on(ticketSystemActions.DELETE_TICKET_BY_ID, (state, action) => {
    ticketId = action.payload;
    return { ...state };
  }),
  on(ticketSystemActions.DELETE_TICKET_BY_ID_FAIL, (state) => ({ ...state })),
  on(ticketSystemActions.DELETE_TICKET_BY_ID_SUCCESS, (state, action) => {
    return adapter.removeOne(ticketId, state);
  })
);
export function reducer(state: FeatureState, action: Action) {
  return ticketReducer(state, action);
}
