import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as walletActions from '../actions/wallet.action';
import { IMovements, IWallet } from '../models/interfaces/wallet.model';
export const walletFeatureKey = 'walletModule';
export const adapter: EntityAdapter<IWallet|any> = createEntityAdapter<IWallet>({ selectId: selectWalletId });
export function selectWalletId(a: IWallet|any): string { return a?._id as string; }
export interface FeatureState extends EntityState<IWallet|any> {
  wallet: IWallet|any;
  updateWallet: IWallet|any;
  updateSucceeded: boolean;
  message: any;
  userMovement: IMovements;
  adminMovement: IMovements;
}
export interface AppState { walletModule: FeatureState; }

export const selectFeature = (state: AppState) => state.walletModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureWallets = createSelector(selectFeature, (state: FeatureState) => state);
export const selectAllWalletEntities = createSelector(selectFeatureWallets, selectAll);
export const selectAllWallet = createSelector(selectFeatureWallets, selectEntities);
export const selectAdminBalance = createSelector(selectFeatureWallets, (state: FeatureState) => state.updateWallet);
export const selectCreateWallet = createSelector(selectFeatureWallets, (state: FeatureState) => state.wallet);
export const selectUserMovement = createSelector(selectFeatureWallets, (state: FeatureState) => state.userMovement);
export const selectAdminMovement = createSelector(selectFeatureWallets, (state: FeatureState) => state.adminMovement);
export const selectWalletBalance = createSelector(selectFeatureWallets, (state: FeatureState) => state.updateWallet);

export const initialState: FeatureState = adapter.getInitialState({
  wallet: {},
  updateWallet: {},
  updateSucceeded: false,
  message: {},
  userMovement: {},
  adminMovement: {}
});
const walletReducer = createReducer(
  initialState,
  on(walletActions.GET_ALL_WALLETS_USER, (state) => { return adapter.removeAll(state); }),
  on(walletActions.GET_ALL_WALLETS_USER_FAIL, (state) => ({ ...state })),
  on(walletActions.GET_ALL_WALLETS_USER_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),
  on(walletActions.GET_ALL_WALLETS, (state) => { return adapter.removeAll(state); }),
  on(walletActions.GET_ALL_WALLETS_FAIL, (state) => ({ ...state })),
  on(walletActions.GET_ALL_WALLETS_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),
  on(walletActions.GET_MOVEMENTS, (state) => ({ ...state, adminMovement: {} })),
  on(walletActions.GET_MOVEMENTS_FAIL, (state, action) => { return { ...state, adminMovement: action.payload }; }),
  on(walletActions.GET_MOVEMENTS_SUCCESS, (state, action) => { return { ...state, adminMovement: action.payload }; }),

  on(walletActions.GET_USER_MOVEMENTS, (state) => ({ ...state, userMovement: {} })),
  on(walletActions.GET_USER_MOVEMENTS_FAIL, (state, action) => { return { ...state, userMovement: action.payload }; }),
  on(walletActions.GET_USER_MOVEMENTS_SUCCESS, (state, action) => { return { ...state, userMovement: action.payload }; }),

  on(walletActions.CREATE_WALLET, (state) => ({ ...state, wallet: {} })),
  on(walletActions.CREATE_WALLET_FAIL, (state, action) => {
    return { ...state, wallet: action.payload };
  }),
  on(walletActions.CREATE_WALLET_SUCCESS, (state, action) => { return adapter.addOne(action.payload, { ...state, wallet: action.payload }); }),

  on(walletActions.WALLET_BALANCE, (state) => ({ ...state, updateWallet: {} })),
  on(walletActions.WALLET_BALANCE_FAIL, (state) => ({ ...state, updateWallet: {} })),
  on(walletActions.WALLET_BALANCE_SUCCESS, (state, action) => {
    return { ...state, updateWallet: action.payload };
  }),

  on(walletActions.ADMIN_BALANCE, (state) => ({ ...state, updateSucceeded: false })),
  on(walletActions.ADMIN_BALANCE_FAIL, (state) => ({ ...state, updateSucceeded: false })),
  on(walletActions.ADMIN_BALANCE_SUCCESS, (state, action) => { return { ...state, updateWallet: action.payload, updateSucceeded: true }; }),

);
export function reducer(state: FeatureState, action: Action) { return walletReducer(state, action); }
