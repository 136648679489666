<section class="privacy-policy">
  <div class="container position-relative">
    <h2 class="title-footer">Privacy Policy</h2>
    <div class="row">
      <div class="col-md-12">
        <p class="desc mb-0">
          All codes send via the registered email and you can find the codes
          also in your account on the website, check orders then click on the
          order ID .
          <br />
          We deliver all codes automatically within a few seconds, how every we
          may delay the delivery for some reason from a few minutes up to 24
          Hours. We have the right to cancel the order and issue a full refund
          for you. If we suspect any transaction we may ask for verification
          documents . <br />
          paying via website wallet is the best way to get all codes instantly
          without any verification .
        </p>
      </div>
    </div>
  </div>
</section>
