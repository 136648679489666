import { CartService } from 'projects/shared/services/cart.service';
import { AuthService } from './../../../shared/services/auth.service';
import { ExchangeService } from './../../../shared/services/exchange.service';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IUser } from 'projects/shared/models/interfaces/user.model';
import { Subscription, filter } from 'rxjs';
import { MessageService } from 'primeng/api';
import { error } from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isVisible: boolean = false;
  showHeader = true;
  user!: IUser | null;
  showLoading = true;
  subscription: Subscription = new Subscription();

  private renderer!: Renderer2;
  activeCurrency!: string;
  constructor(
    public router: Router,
    private authServ: AuthService,
    private storeChangeServ: StoreChangesService,
    private exchangeServ: ExchangeService,
    private rootRenderer: RendererFactory2,
    private cartService: CartService,
    private storeChangesServ: StoreChangesService,
    private messageService: MessageService,
  ) {
    this.getThameBody();
    // this.loginMessage()
  }


  getThameBody() {
    const body = document.querySelector('body');
  }

  ngOnInit(): void {
    localStorage.removeItem("mainCategoryList");
    // this.renderer = this.rootRenderer.createRenderer(
    //   document.querySelector('html'),
    //   null
    // );
    this.validateUser()
    this.scrollTop();
  }

  scrollTop() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        window.scroll(0, 0);
      });
  }

  validateUser() {
    this.subscription.add(
      this.authServ.validate().subscribe({
        next: (response) => {
          sessionStorage.setItem('userExist', 'true')
          this.user = response;
          this.getUserCart();
          this.storeChangeServ.setUser(response);
          this.authServ.updateUserShop(response);
          this.onLoopComplete()
        }, error: (response) => {
          sessionStorage.setItem('userExist', 'false')
          this.onLoopComplete()
        }
      })
    );
  }



  getUserCart(): void {
    const storedCurrency = JSON.parse(
      localStorage.getItem('activatedCurrency') + ''
    );
    if (storedCurrency) this.activeCurrency = storedCurrency?.key;
    else this.activeCurrency = 'USD';
    this.subscription.add(
      this.cartService.getCart(this.activeCurrency).subscribe({
        next: (response) => {
          this.storeChangeServ.setCart(response);
        },
      })
    );
  }

  ngAfterViewInit(): void {
    // this.onLoopComplete();
    // this.renderer.listen('window', 'load', () => {
    // this.validateUser();
    // });
  }

  getExchangeRate() {
    this.subscription.add(
      this.exchangeServ.getUserExchange().subscribe((exchange) => {
        this.storeChangeServ.setExchange(exchange);
      })
    );
  }

  onLoopComplete(): void {
    setTimeout(() => {
      this.showLoading = false;
    }, 2500);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    // localStorage.removeItem("mainCategoryList");
  }
}
