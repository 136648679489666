import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as notificationActions from '../actions/notification.action';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/interfaces/notification';
@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
  public loadAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.GET_ALL_NOTIFICATIONS.type),
      mergeMap((action: notificationActions.ActionWithPayload<any>) => {
        return this.notificationService.getAllNotifications().pipe(
          retry(1),
          map((response) =>
            notificationActions.GET_ALL_NOTIFICATIONS_SUCCESS({
              payload: response,
            })
          ),
          catchError((error) =>
            of(
              notificationActions.GET_ALL_NOTIFICATIONS_FAIL({ payload: error })
            )
          )
        );
      })
    )
  );
  public errorOnLoadAllNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.GET_ALL_NOTIFICATIONS_FAIL.type),
        tap((action: notificationActions.ActionWithPayload<any>) =>
          console.log(action.payload)
        )
      ),
    { dispatch: false }
  );
  public loadUserNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.GET_USER_NOTIFICATIONS.type),
      mergeMap((action: notificationActions.ActionWithPayload<any>) => {
        return this.notificationService.getAllNotifications().pipe(
          retry(1),
          map((response) =>
            notificationActions.GET_USER_NOTIFICATIONS_SUCCESS({
              payload: response,
            })
          ),
          catchError((error) =>
            of(
              notificationActions.GET_USER_NOTIFICATIONS_FAIL({
                payload: error,
              })
            )
          )
        );
      })
    )
  );
  public errorOnLoadUserNotifications$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.GET_USER_NOTIFICATIONS_FAIL.type),
        tap((action: notificationActions.ActionWithPayload<any>) =>
          console.log(action.payload)
        )
      ),
    { dispatch: false }
  );
  public getNotificationById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.GET_NOTIFICATION.type),
      mergeMap((action: notificationActions.ActionWithPayload<string>) => {
        return this.notificationService.getNotification(action.payload).pipe(
          retry(1),
          map((response) =>
            notificationActions.GET_NOTIFICATION_SUCCESS({ payload: response })
          ),
          catchError((error) =>
            of(notificationActions.GET_NOTIFICATION_FAIL({ payload: error }))
          )
        );
      })
    )
  );
  public errorOnGetNotificationById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.GET_NOTIFICATION_FAIL.type),
        tap((action: notificationActions.ActionWithPayload<any>) =>
          console.log(action.payload)
        )
      ),
    { dispatch: false }
  );
  public addNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.CREATE_NOTIFICATION.type),
      mergeMap(
        (action: notificationActions.ActionWithPayload<Notification>) => {
          return this.notificationService
            .createNotifications(action.payload)
            .pipe(
              retry(1),
              map((response) => {
                return notificationActions.CREATE_NOTIFICATION_SUCCESS({
                  payload: response,
                });
              }),
              catchError((error) =>
                of(
                  notificationActions.CREATE_NOTIFICATION_FAIL({
                    payload: error,
                  })
                )
              )
            );
        }
      )
    )
  );
  public errorOnAddNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(notificationActions.CREATE_NOTIFICATION_FAIL.type),
        tap((action: notificationActions.ActionWithPayload<any>) =>
          console.log(action.payload)
        )
      ),
    { dispatch: false }
  );
  public onReadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.READ_NOTIFICATION.type),
      mergeMap((action: notificationActions.ActionWithPayload<string>) => {
        return this.notificationService.readNotifications(action.payload).pipe(
          retry(1),
          map((response) => {
            return notificationActions.READ_NOTIFICATION_SUCCESS({
              payload: response,
            });
          }),
          catchError((error) =>
            of(notificationActions.READ_NOTIFICATION_FAIL({ payload: error }))
          )
        );
      })
    )
  );
  public onDeleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(notificationActions.DELETE_NOTIFICATION.type),
      mergeMap((action: notificationActions.ActionWithPayload<string>) => {
        return this.notificationService
          .deleteNotifications(action.payload)
          .pipe(
            retry(1),
            map((response) => {
              return notificationActions.DELETE_NOTIFICATION_SUCCESS({
                payload: response,
              });
            }),
            catchError((error) =>
              of(
                notificationActions.DELETE_NOTIFICATION_FAIL({ payload: error })
              )
            )
          );
      })
    )
  );
}
