import { Notification } from '../models/interfaces/notification';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as notificationActions from '../actions/notification.action';
export const notificationFeatureKey = 'notificationModule';
export const adapter: EntityAdapter<Notification> =
  createEntityAdapter<Notification>({ selectId: selectNotificationId });
export function selectNotificationId(x: Notification): string {
  return x._id as string;
}
export interface FeatureState extends EntityState<Notification> {
  getNotification: any;
  addedNotification: any | undefined;
  updateSucceeded: boolean;
}

export interface AppState {
  notificationModule: FeatureState;
}
export const selectFeature = (state: AppState) => state.notificationModule;
export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

export const featureNotification = createSelector(
  selectFeature,
  (state: FeatureState) => state
);
export const selectNotifications = createSelector(
  featureNotification,
  selectEntities
);
export const selectAllNotifications = createSelector(
  featureNotification,
  selectAll
);
export const selectUserNotifications = createSelector(
  featureNotification,
  selectAll
);
export const selectAddedNotification = createSelector(
  featureNotification,
  (state: FeatureState) => state.addedNotification
);
export const selectNotification = createSelector(
  featureNotification,
  (state: FeatureState) => state.getNotification
);
export const initialState: FeatureState = adapter.getInitialState({
  getNotification: {},
  addedNotification: {},
  updateSucceeded: false,
});

const notificationReducer = createReducer(
  initialState,
  on(notificationActions.GET_ALL_NOTIFICATIONS, (state) => {
    return adapter.removeAll(state);
  }),
  on(notificationActions.GET_ALL_NOTIFICATIONS_FAIL, (state) => ({ ...state })),
  on(notificationActions.GET_ALL_NOTIFICATIONS_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),

  on(notificationActions.GET_USER_NOTIFICATIONS, (state) => {
    return adapter.removeAll(state);
  }),
  on(notificationActions.GET_USER_NOTIFICATIONS_FAIL, (state) => ({
    ...state,
  })),
  on(notificationActions.GET_USER_NOTIFICATIONS_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),

  on(notificationActions.GET_NOTIFICATION, (state) => ({
    ...state,
    getNotification: {},
  })),
  on(notificationActions.GET_NOTIFICATION_FAIL, (state) => ({ ...state })),
  on(notificationActions.GET_NOTIFICATION_SUCCESS, (state, action) => {
    return { ...state, getNotification: action.payload };
  }),

  on(notificationActions.CREATE_NOTIFICATION, (state) => ({
    ...state,
    addedNotification: {},
  })),
  on(notificationActions.CREATE_NOTIFICATION_FAIL, (state, action) => {
    return { ...state, addedNotification: undefined };
  }),
  on(notificationActions.CREATE_NOTIFICATION_SUCCESS, (state, action) => {
    return adapter.addOne(action.payload, {
      ...state,
      addedNotification: action.payload,
    });
  }),

  on(notificationActions.READ_NOTIFICATION, (state, action) => ({
    ...state,
    getNotification: {},
    updateSucceeded: false,
  })),
  on(notificationActions.READ_NOTIFICATION_FAIL, (state) => ({
    ...state,
    updateSucceeded: false,
  })),
  on(notificationActions.READ_NOTIFICATION_SUCCESS, (state, action) => {
    return { ...state, getNotification: action.payload, updateSucceeded: true };
  }),

  on(notificationActions.DELETE_NOTIFICATION, (state) => ({ ...state })),
  on(notificationActions.DELETE_NOTIFICATION_FAIL, (state) => ({ ...state })),
  on(notificationActions.DELETE_NOTIFICATION_SUCCESS, (state, action) => {
    return { ...state };
  })
);
export function reducer(state: FeatureState, action: Action) {
  return notificationReducer(state, action);
}
