<section class="contact-us">
  <div class="container">
    <!-- <p-toast></p-toast> -->
    <div class="row vh-100">
      <div class="col-md-6">
        <div class="card-contact">
          <h4 class="title">Contact Form</h4>
          <p class="desc">Please enter your information</p>
          <form class=" auth-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-12 mb-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                  </span>
                  <input pInputText placeholder="Your Name" formControlName="fullName" />
                </div>
                <span class="error d-block text-danger pt-2"
                  *ngIf="getControl('name')?.touched && !getControl('name')?.valid">
                  <span *ngIf="getControl('name')?.errors?.required">
                    - This Field is Required
                  </span>
                </span>
              </div>
              <div class="col-md-12 mb-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-envelope"></i>
                  </span>
                  <input pInputText type="text" placeholder="Your email adress" formControlName="email" />
                </div>
                <span class="error d-block text-danger pt-2"
                  *ngIf="getControl('email')?.touched && !getControl('email')?.valid">
                  <span *ngIf="getControl('email')?.errors?.email">
                    - This Field is Not valid
                  </span>
                  <span *ngIf="getControl('email')?.errors?.required">
                    - This Field is Required
                  </span>
                </span>
              </div>
              <div class="col-md-12 mb-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-phone"></i>
                  </span>
                  <input pInputText type="text" (keypress)="restrictNumeric($event)" placeholder="Your phone (optional)"
                    formControlName="phone" />
                </div>

              </div>
              <div class="col-md-12 mb-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="pi pi-window-maximize"></i>
                  </span>
                  <input pInputText type="text" placeholder="Your website (optional)" formControlName="website" />
                </div>

              </div>
              <div class="col-md-12 mb-3">
                <textarea rows="3" class="w-100" formControlName="message" placeholder="Type your message here..."
                  cols="30" pInputTextarea></textarea>
              </div>
              <div class="com-md-12 mb-4">
                <button class="btn-main w-100" type="submit" [disabled]="contactForm.invalid || loading">Send</button>
              </div>
            </div>
          </form>
        </div>


      </div>
      <div class="col-md-6 d-none d-md-block">
        <div class="d-flex justify-content-center align-items-center">
          <img class="img-fluid" src="../../../../assets/images/footer/contact-us/Sent Message-amico 1.svg"
            alt="img contact">
        </div>
      </div>
    </div>
  </div>
</section>
