import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { WalletService } from "../services/wallet.service";
import * as walletActions from '../actions/wallet.action';
import { catchError, map, mergeMap, of, retry, tap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
@Injectable()
export class WalletEffects {
  constructor(private actions$: Actions, private walletService: WalletService) { }
  public getUserWallets$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_ALL_WALLETS_USER.type),
    mergeMap((action: walletActions.ActionWithPayload<string>) => {
      return this.walletService.getUserWallets().pipe(
        retry(1),
        map((response) => {
          return walletActions.GET_ALL_WALLETS_USER_SUCCESS({ payload: response })
        }),
        catchError((error) => of(walletActions.GET_ALL_WALLETS_USER_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnLoadAllUserWallets$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_ALL_WALLETS_USER_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public getMovements$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_MOVEMENTS.type),
    mergeMap((action: walletActions.ActionWithPayload<string>) => {
      return this.walletService.getMovements(action.payload).pipe(
        retry(1),
        map((response) => {
          return walletActions.GET_MOVEMENTS_SUCCESS({ payload: response })
        }),
        catchError((error) => of(walletActions.GET_MOVEMENTS_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnLoadMovements$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_MOVEMENTS_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => { return (action.payload) })),
    { dispatch: false },
  );
  public getUserMovements$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_USER_MOVEMENTS.type),
    mergeMap((action: walletActions.ActionWithPayload<string>) => {
      return this.walletService.getUserMovements(action.payload).pipe(
        retry(1),
        map((response) => {
          return walletActions.GET_USER_MOVEMENTS_SUCCESS({ payload: response })
        }),
        catchError((error) => of(walletActions.GET_USER_MOVEMENTS_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnLoadUserMovements$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_USER_MOVEMENTS_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => { return (action.payload) })),
    { dispatch: false },
  );
  public getWallets$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_ALL_WALLETS.type),
    mergeMap(() => {
      return this.walletService.getWallets().pipe(
        retry(1),
        map((response) => {
          return walletActions.GET_ALL_WALLETS_SUCCESS({ payload: response.data })
        }),
        catchError((error) => of(walletActions.GET_ALL_WALLETS_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnLoadAllWallets$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.GET_ALL_WALLETS_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public createWalletEffect$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.CREATE_WALLET.type),
    mergeMap((action: walletActions.ActionWithPayload<any>) => {
      return this.walletService.createWallet(action.payload).pipe(
        retry(1),
        map((response) => { return walletActions.CREATE_WALLET_SUCCESS({ payload: response }); }),
        catchError((error: HttpErrorResponse) => of(walletActions.CREATE_WALLET_FAIL({ payload: error.error }))),
      );
    }),
  ));
  public errorCreateWallet$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.CREATE_WALLET_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  // public walletBalanceEffect$ = createEffect(() => this.actions$.pipe(
  //   ofType(walletActions.WALLET_BALANCE.type),
  //   mergeMap((action: walletActions.ActionWithPayload<Balance>) => {
  //     return this.walletService.walletBalance(action.payload).pipe(
  //       retry(1),
  //       map((response) => { return walletActions.WALLET_BALANCE_SUCCESS({ payload: response }); }),
  //       catchError((error) => of(walletActions.WALLET_BALANCE_FAIL({ payload: error }))),
  //     );
  //   }),
  // ));
  public errorWalletBalance$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.WALLET_BALANCE_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  public adminTopUpEffect$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.ADMIN_BALANCE.type),
    mergeMap((action: walletActions.ActionWithPayload<any>) => {
      return this.walletService.adminTopUp(action.payload.amount!,action.payload.id!).pipe(
        retry(1),
        map((response) => { return walletActions.ADMIN_BALANCE_SUCCESS({ payload: response }); }),
        catchError((error) => of(walletActions.ADMIN_BALANCE_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorAdminTopUp$ = createEffect(() => this.actions$.pipe(
    ofType(walletActions.ADMIN_BALANCE_FAIL.type),
    tap((action: walletActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
}
