<section class="about-us">
  <div class="container position-relative">
    <h2 class="title-footer">
      About Us
    </h2>
    <div class="row">
      <div class="col-md-12">
        <p class="desc mb-0">
          Card4Games is a leading provider of game cards and gift cards, dedicated to enhancing the digital and gaming
          experience for users worldwide. Our diverse range of products offers seamless access to a variety of online
          games, entertainment services, and retail options, providing customers with the flexibility and convenience
          they need.
        </p>
        <p class="desc mb-0">
          At Card4Games Team, we understand the passion and excitement that gaming and gifting bring. Our mission is to
          support this enthusiasm by offering high-quality, reliable game cards and gift cards that cater to the diverse
          needs of our community. Whether you’re looking to unlock new levels, purchase in-game items, access exclusive
          content, or give the perfect gift, our cards are designed to deliver a smooth and enjoyable experience.
        </p>
        <p class="desc mb-0">
          Our team of experts is committed to staying ahead of industry trends and continuously improving our products.
          We prioritize security and ease of use, ensuring that our customers can trust our cards for all their gaming
          and gifting needs. With instant delivery and a user-friendly interface, Card4Games.com makes it easy for users
          to get the most out of their favorite games and shopping experiences.
        </p>
        <p class="desc mb-0">
          Join us in revolutionizing the way you play and gift. With Card4Games.com, the ultimate gaming and gifting
          experience is just a card away.
          about us detail
        </p>

      </div>
    </div>
  </div>
</section>