import { AuthService } from 'projects/shared/services/auth.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingData } from 'projects/shared/models/enums/loading.enum';
import { ProductType } from 'projects/shared/models/enums/productTypes.enum';
import { NotificationStoreProvider } from 'projects/shared/providers/notification.provider';
import { UserStoreProvider } from 'projects/shared/providers/user.provider';
import { CartService } from 'projects/shared/services/cart.service';
import { ExchangeService } from 'projects/shared/services/exchange.service';
import { LoadingSpinnerService } from 'projects/shared/services/loading-spinner.service';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
import { getDateSubtraction, sortWallets } from 'projects/shared/utils/helpers';
import { CartHelper } from 'projects/shop/src/app/+shared/helpers/cart.helper';
import { Observable, Subscription, map } from 'rxjs';
import { OutsideClickDirective } from 'projects/shared/directives/outSideClick.directive';

import { MainCategory, MainCategory1 } from 'projects/shared/models/category';
import { MainCategoryStoreProvider } from 'projects/shared/providers/category/main-category.provider';
import { Store } from '@ngrx/store';

import {
  IActiveCurrency,
  Exchange,
} from 'projects/shared/models/interfaces/exchange.model';
import {
  AccountStatus,
  IUser,
  Rank,
} from 'projects/shared/models/interfaces/user.model';
import { ICartItem } from 'projects/shared/models/interfaces/cart.model';
import { Notification } from 'projects/shared/models/interfaces/notification';
import { NotificationService } from 'projects/shared/services/notification.service';
import { WalletService } from 'projects/shared/services/wallet.service';
import { IWallet } from 'projects/shared/models/interfaces/wallet.model';
import { Currency } from 'projects/shared/models/exchange.model';
import { CategoryService } from 'projects/shared/services/category.service';

@Component({
  selector: 'app-navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss'],
  viewProviders: [OutsideClickDirective],
})
export class NavbarHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  searchTerm!: string;

  user!: IUser | null;
  email: any;
  notifications: Notification[] = [];
  alert: any;
  message: any;
  cartItemsNum = 0;
  esimData!: any[];
  totalPrice: number | undefined = 0;
  isLoggedOut = false;
  loadingNav = false;
  // loading = LoadingData;
  loading = false;
  isLoading$: any;

  cartItems: any;
  productType = ProductType;
  searchInput: any;
  accountStatus = AccountStatus;
  userRank = Rank;
  exchange!: Exchange;
  exchangeRate: any;
  currency!: IActiveCurrency;
  mainCategories$: Observable<MainCategory[]> | any;
  currencyList = [
    {
      currency: 'USD',
      icon: '../../../../../assets/images/navbar/currency-imgs/USD.svg',
    },
    {
      currency: 'EGP',
      icon: '../../../../../assets/images/navbar/currency-imgs/EGP.svg',
    },
    {
      currency: 'EUR',
      icon: '../../../../../assets/images/navbar/currency-imgs/eur.svg',
    },
    {
      currency: 'SAR',
      icon: '../../../../../assets/images/navbar/currency-imgs/sar.svg',
    },
    {
      currency: 'AED',
      icon: '../../../../../assets/images/navbar/currency-imgs/aed.svg',
    },
  ];
  selectedCurrency: IActiveCurrency = {
    key: Currency.USD,
    value: 1,
  };
  updatedCart!: any[];
  mergedProducts!: any[];
  currentUrl!: string;
  defaultAvatar =
    'https://innostudio.de/fileuploader/images/default-avatar.png';
  private cartHelper: CartHelper;

  showCurrency!: boolean;
  showNotification!: boolean;
  showUser: boolean = false;
  showCard!: boolean;
  showLogin!: boolean;
  subscription: Subscription = new Subscription();
  mainCategoryList?: MainCategory1[];


  listLinksNav: boolean = false;
  currencyListNav: boolean = false;
  loginListNav: boolean = false;
  userListNav: boolean = false;
  screenWidth!: number;
  disableCurrency = false;
  wallets: any;
  selectedWallet!: Partial<IWallet>;
  notificationLength: number = 0;
  notificationsCount: number = 0;
  totalPriceEsim = 0;
  newOrNo = false
  esimDataMapping!: any[];
  exchangeTwo!: any;
  loadingNotification = false;
  constructor(
    private storeChangesServ: StoreChangesService,
    private notificationStore: NotificationStoreProvider,
    private notificationServ: NotificationService,
    private userStore: UserStoreProvider,
    private cartServ: CartService,
    private router: Router,
    private messageService: MessageService,
    private loadingSpinnerService: LoadingSpinnerService,
    private exchangeServ: ExchangeService,
    private authService: AuthService,
    private mainCategoryStore: MainCategoryStoreProvider,
    private cdr: ChangeDetectorRef,
    private walletServ: WalletService,
    private categoryService: CategoryService,
    // public store: Store<fromFeature.AppState>
  ) {
    this.isLoading$ = this.loadingSpinnerService.isLoading$;
    this.cartHelper = new CartHelper();
    this.getScreenSize();
  }

  ngOnInit(): void {
    // this.getWallets();
    this.ChangeAvter()
    this.checkRoute(this.router.url);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRoute(this.router.url);
      }
    });

    this.getAllMainCategories();
    this.subscription.add(
      this.storeChangesServ.getUser().subscribe({
        next: (response) => {
          this.user = response;
          this.getValidUser();
        },
      })
    );
    this.getExchangeRate();
    const storedCurrency = JSON.parse(
      localStorage.getItem('activatedCurrency') + ''
    );
    if (storedCurrency) {
      this.selectedCurrency = storedCurrency;
      this.storeChangesServ.setActivatedCurrency(this.selectedCurrency);
    }
    this.subscription.add(
      this.storeChangesServ.getCartNumber().subscribe({
        next: (response) => {
          this.cartItemsNum = response;
        },
      })
    );
    this.subscription.add(
      this.storeChangesServ.getReadNotificationsCount().subscribe({
        next: (res) => {
          this.notificationsCount = res;
        },
      })
    );
    this.subscription.add(
      this.storeChangesServ.getBalance().subscribe(x => {
        x != 0 ? this.getWallets() : ''
      })
    )
    this.logOutFromProfile()
  }



  getWallets() {
    this.subscription.add(
      this.walletServ.getUserWallets().subscribe({
        next: (response) => {
          if (response && response.length) {
            this.wallets = sortWallets(response, this.selectedCurrency.key).filter((item: IWallet) => {
              return item.currency === this.selectedCurrency.key;
            });
          }
          if (this.wallets.length > 0) {
            this.selectedWallet = this.wallets[0];
            this.storeChangesServ.setSelectedWallet(this.selectedWallet)
          }
          else
            this.selectedWallet = {
              balance: 0,
              currency: this.selectedCurrency.key,
            };
        },
      })
    );
  }

  private checkRoute(url: string): void {
    if (url === '/checkout') {
      this.disableCurrency = true;
    } else {
      this.disableCurrency = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }



  getAllMainCategories() {

    if (localStorage.getItem("mainCategoryList")) {
      let retString: any = localStorage.getItem("mainCategoryList")
      let retArray = JSON.parse(retString)
      this.mainCategoryList = retArray
    } else {
      const query = "?sort=order";
      this.categoryService.getUserMainCategories(query).subscribe((response) => {
        this.mainCategoryList = response.data;
        // console.log(this.mainCategoryList)
      });
    }
    console.log('in navbar header')
    // this.storeChangesServ.getCategoryFromParent().subscribe((response) => {
    //   this.mainCategoryList = response.data;
    //   console.log(this.mainCategoryList)
    // })
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        const regex = new RegExp('orders-status');
        const urlContains = regex.test(this.currentUrl);
        // if ((this.currentUrl = '/')) {
        //   // this.getValidUser();
        // }
        // if (urlContains == true) {
        //   // this.getWallets();
        // }
      }
    });
  }

  getValidUser() {
    if (this.user?._id) {
      this.getStoredCart();
      this.getCartLength();
      this.getUnreadNymberNotifications();
      this.getWallets();
      this.loadingNav = true;
    }
  }



  public deleteNotification(id?: string): void {
    if (id) {
      this.notificationStore.deleteNotification(id);
      if (this.notifications?.find((moderator) => moderator._id === id))
        this.notifications = this.notifications.filter(
          (moderator) => moderator._id !== id
        );
    }
  }
  public getDate(date?: string): string {
    return date ? getDateSubtraction(date) : '';
  }

  getNotification() {
    this.loadingNotification = true
    this.subscription.add(this.notificationServ.getUserNotifications({ limit: 4, skip: 0 }).subscribe({
      next: (response) => {
        this.loadingNotification = false
        this.notifications = response.data;
        this.notificationLength = response.total;
        this.notifications = this.notifications.sort((a, b) => new Date(b.createdAt as string).getTime() - new Date(a.createdAt as string).getTime());
      },
    })
    );
  }

  getUnreadNymberNotifications(): void {
    this.subscription.add(
      this.notificationServ.getUnreadNotificationNumber().subscribe({
        next: (response) => {
          this.notificationsCount = response?.count;
        },
      })
    );
  }

  updateCurrency(currency: string) {
    Object.entries(this.exchange).forEach(([key, value]) => {
      if (key === currency) {
        this.selectedCurrency.key = key;
        this.selectedCurrency.value = value;
        localStorage.setItem('activatedCurrency', JSON.stringify(this.selectedCurrency));
        this.storeChangesServ.setActivatedCurrency(this.selectedCurrency);
        if (this.user?._id) {
          this.getCart();
          this.getWallets();
        }
      }
    });
    if (this.screenWidth <= 768) {
      this.currencyListNav = !this.currencyListNav;
    }
  }

  getExchangeRate() {
    this.exchangeServ.getUserExchange().subscribe((exchange) => {
      this.exchange = exchange;
      Object.entries(exchange).forEach(([key, value]) => {
        if (key === this.selectedCurrency.key) {
          this.selectedCurrency.value = value;
        }
      });
    });
  }

  getStoredCart(): void {
    this.subscription.add(
      this.storeChangesServ.getCart().subscribe({
        next: (response) => {
          if (response?.esim_data) {
            // console.log(this.esimData)
            this.esimData = response?.esim_data
            // console.log(this.esimData)
          }
          if (response?.products) {
            this.cartItems = this.cartHelper.sumAndRemoveDuplicates(response.products!);
          } else this.cartItems = [];
          this.totalPrice = response?.total;
          this.mergeProduct()
          this.getExchange()
          // console.log(this.mergedProducts);
        },
      })
    );
  }
  mergeProduct() {
    const mapItemsWithType = (items: any, type: string) => {
      return items.map((item: any) => ({
        id: item.id || item.product,  // Use 'id' from esimData or 'product' from products
        name: item.name,
        icon: item.icon,
        quantity: item.quantity,
        price: item.price,
        type: type  // Assign the type dynamically
      }));
    };

    // Merging the arrays with their respective types
    this.mergedProducts = [
      ...mapItemsWithType(this.esimData, 'sim'),  // Assign 'sim' type for esimData array
      ...mapItemsWithType(this.cartItems, 'ourProduct')  // Assign 'ourProduct' type for products array
    ];
  }

  getCart() {
    this.loading = true
    this.subscription.add(
      this.cartServ.getCart(this.selectedCurrency.key).subscribe({
        next: (response) => {
          if (response.esim_data) {
            // console.log(this.esimData)
            this.esimData = response.esim_data
            // this.esimData.forEach(item => {this.totalPriceEsim += item.price;});
            // console.log(this.esimData)
          }
          this.cartItems = this.cartHelper.sumAndRemoveDuplicates(response.products).slice(0, 4);
          this.storeChangesServ.setCart(response);
          this.totalPrice = response.total;

          this.mergeProduct()

          // console.log(mergedArray);

          this.loading = false
          if (response.products.length > 0 || response.esim_data.length > 0) {
            this.getCartLength();
          } else this.cartItemsNum = 0;
          // this.getExchange()
        },
      })
    );
  }

  getCartLength(): void {
    this.subscription.add(
      this.cartServ.getCartNumber().subscribe((response) => {
        this.cartItemsNum = response.totalQuantity!;
        this.storeChangesServ.setCartNumber(response.totalQuantity);
      })
    );
  }


  ChangeAvter() {
    this.subscription.add(
      this.storeChangesServ.getAvter().subscribe({
        next: (response) => {
          this.user = response;
        }
      }
      )
    )
  }


  removeCartItem(idToRemove: number, typeProduct: string): void {
    this.loading = true
    // console.log(this.updatedCart, this.esimData)
    if (typeProduct == 'ourProduct') {
      this.updatedCart = this.cartItems.filter((item: any) => item.product !== idToRemove).map((prod: ICartItem) => {
        return { product: prod.product, quantity: prod.quantity!, rangePrice: prod.rangePrice, userInputs: prod.userInputs, };
      });
    } else {
      this.esimData = this.esimData.filter(item => {
        // console.log(item.id == idToRemove)
        return item.id != idToRemove
      });
      this.updatedCart = this.cartItems.map((prod: ICartItem) => {
        return {
          product: prod.product,
          quantity: prod.quantity,
          rangePrice: prod.rangePrice,
          userInputs: prod.userInputs,
        };
      });
      console.log(this.updatedCart, this.esimData)
      this.updatedCart == undefined ? this.updatedCart = [] : this.updatedCart
      // console.log(this.updatedCart)
      // this.updatedCart.push(esimDataObject)
    }

    this.subscription.add(
      this.cartServ.addToCart(this.updatedCart, this.esimData).subscribe({
        next: (response) => {
          this.getCart();
          this.updatedCart = response?.products;
          if (this.updatedCart.length == 0 && this.esimData.length == 0) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Cart is empty',
            });
          } else {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Item removed successfully',
            });
          }
          this.loading = false
        },
        error: (response) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: response.error?.message,
          });
        },
      })
    );
  }

  searchResult(): void {
    console.log(this.searchTerm)
    // if(this.searchTerm != '' || this.searchTerm != undefined){
    if (this.searchTerm) {
      this.router.navigate(['/search'], {
        queryParams: { name: this.searchTerm },
      });
    }
  }

  showDropDownCurrency() {
    this.showCurrency = !this.showCurrency;
  }
  showDropDownNotification() {
    this.getNotification();
    this.showNotification = !this.showNotification;
  }
  showDropDownCard() {
    this.showCard = !this.showCard;
  }
  showDropDownUser() {
    this.showUser = !this.showUser;
  }
  showDropDownLogin() {
    this.showLogin = !this.showLogin;
  }
  logOutFromProfile() {
    this.storeChangesServ.getlogOutFromProfile().subscribe((response) => {
      if (response == true) {
        this.logout()
      }
    })
  }
  logout() {
    this.subscription.add(
      this.userStore.logout$().subscribe({
        next: (response) => {
          this.storeChangesServ.setUser(null);
          this.storeChangesServ.setLoginSecret('');
          this.authService.updateUserShop(null);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'You are Logout Successfully',
          });
          this.loadingNav = false;
          this.user = null;
          this.router.navigateByUrl('/');
          this.getValidUser();
        },
        complete: () => { },
      })
    );
  }
  getExchange() {
    this.exchangeServ.getUserExchange().subscribe(exchange => {
      this.exchangeTwo = exchange
      this.esimData = this.esimData.map((item: any) => {
        return {
          id: item.id,
          icon: item.icon,
          description: item.description,
          name: item.name,
          price: item.price / this.exchangeTwo[this.selectedCurrency.key],
          quantity: item.quantity,
        };
      });
      // console.log(exchange, this.esimData, this.exchangeTwo[this.selectedCurrency.key])
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
