import { Observable, catchError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { Exchange } from '../models/interfaces/exchange.model';
@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  API = `${env.apiUrl}exchange`;
  SessionAPI = `${env.apiUrl}utils/current-session`;
  constructor(private http: HttpClient) { }

  getExchange(): Observable<Exchange> {
    return this.http.get<Exchange>(`${this.API}`, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }

  getUserExchange(): Observable<Exchange> {
    return this.http
      .get<Exchange>(`${this.API}/users`, { withCredentials: true })
      .pipe(
        catchError((err) => {
          throw new Error(err.message);
        })
      );
  }
  updateExchange(newExchange: Exchange): Observable<Exchange> {
    return this.http.patch<Exchange>(
      `${this.API}`,
      newExchange,
      { withCredentials: true }
    );
  }

}
