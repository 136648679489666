import { Router } from '@angular/router';
import { InvoiceProducts } from '../models/order';
import { LicenseRes } from '../models/license';
import { IWallet } from '../models/interfaces/wallet.model';

export function getDateSubtraction(date: string): string {
  const diff = Math.abs(new Date().getTime() - new Date(date).getTime());
  let response: string = '';
  if (Math.floor(diff / (1000 * 60)) == 0) response = 'just now';
  else {
    if (
      Math.floor(diff / (1000 * 60)) > 0 &&
      Math.floor(diff / (1000 * 60 * 60)) == 0
    ) {
      if (Math.floor(diff / (1000 * 60)) > 1)
        response = Math.floor(diff / (1000 * 60)) + ' minutes ago';
      else response = 'a minute ago';
    } else if (
      Math.floor(diff / (1000 * 60 * 60)) > 0 &&
      Math.floor(diff / (1000 * 60 * 60 * 24)) == 0
    ) {
      if (Math.floor(diff / (1000 * 60 * 60)) > 1)
        response = Math.floor(diff / (1000 * 60 * 60)) + ' hours ago';
      else response = 'an hour ago';
    } else if (
      Math.floor(diff / (1000 * 60 * 60 * 24)) > 0 &&
      Math.floor(diff / (1000 * 60 * 60 * 24 * 7)) == 0
    ) {
      if (Math.floor(diff / (1000 * 60 * 60 * 24)) > 1)
        response = Math.floor(diff / (1000 * 60 * 60 * 24)) + ' days ago';
      else response = 'a day ago';
    } else if (
      Math.floor(diff / (1000 * 60 * 60 * 24 * 7)) > 0 &&
      Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) == 0
    ) {
      if (Math.floor(diff / (1000 * 60 * 60 * 24 * 7)) > 1)
        response = Math.floor(diff / (1000 * 60 * 60 * 24 * 7)) + ' weeks ago';
      else response = 'a week ago';
    } else if (
      Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) > 0 &&
      Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25)) == 0
    ) {
      if (Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) > 1)
        response =
          Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) + ' months ago';
      else response = 'a month ago';
    } else if (Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25)) > 0) {
      if (Math.floor(diff / (1000 * 60 * 60 * 24 * 30)) > 1)
        response =
          Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25)) + ' years ago';
      else response = 'a year ago';
    }
  }
  return response;
}
export function getResponseMessage(
  module: string,
  mode: string,
  isSuccess: boolean
): string {
  const updatedMode = isSuccess
    ? `${mode.slice(0, -1)}ed`
    : `${mode.slice(0, -1)}ing`;
  return isSuccess
    ? `${module} is/are ${updatedMode} successfully`
    : `There is something wrong while ${updatedMode} this ${module.toLowerCase()}`;
}
export function getObjectKeysAndValues(object: Object): any {
  const response = [];
  const keys = Object.keys(object);
  const values = Object.values(object);
  for (const key of keys)
    response.push({ key, value: values[keys.indexOf(key)] });
  return response;
}
export function reload(router: Router) {
  let currentUrl = router.url;
  router.routeReuseStrategy.shouldReuseRoute = () => false;
  router.onSameUrlNavigation = 'reload';
  router.navigate([currentUrl]);
}

export const uniqueArray = (arr: any[]) => {
  return arr?.filter((v, i, a) => {
    return a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i;
  });
};

export const mapOrderProducts = (products: any[]) => {
  const productsData = products?.map((product) => {
    const filteredProducts = products?.filter((p) => {
      return p.product._id === product.product._id;
    }) as any[];

    let qty = 0;
    for (const item of filteredProducts) {
      qty += item.quantity;
    }

    const res = JSON.parse(JSON.stringify(product));
    res.quantity = qty;

    return res;
  }) as any[];

  const mappedProducts = uniqueArray(productsData);

  return mappedProducts;
};

export const mapInvoiceProducts = (products: InvoiceProducts[]) => {
  const data = products.map((product) => {
    const invProducts = products.filter((i) => {
      return i.product._id === product.product._id;
    });
    let licenses: LicenseRes[] = [];
    for (const invProduct of invProducts) {
      licenses = licenses.concat(invProduct.licenses);
    }

    const res = JSON.parse(JSON.stringify(product));
    res.licenses = licenses;
    res.quantity = licenses.length;

    return res;
  });

  const mappedProducts = uniqueArray(data);
  return mappedProducts;
};

export const sortWallets = (wallets: IWallet[], currentCurrency: string) => {
  const defaultWallet = wallets.find((wallet) => {
    return wallet.currency === currentCurrency;
  }) as IWallet;

  let res = [defaultWallet];

  const otherWallets = wallets.filter((wallet) => {
    return wallet.currency !== defaultWallet?.currency;
  });

  if (!otherWallets.length) {
    res = wallets;
  } else {
    res = res.concat(otherWallets);
  }

  return res.filter((item) => !!item);
};
// src/app/data/country-codes.ts
export let countryCodes: { [key: string]: string } = {
  "afghanistan": "AF",
  "albania": "AL",
  "algeria": "DZ",
  "andorra": "AD",
  "anguilla": "AI",
  "antigua-and-barbuda": "AG",
  "antilles": "AN",
  "aruba": "AW",
  "angola": "AO",
  "argentina": "AR",
  "armenia": "AM",
  "australia": "AU",
  "austria": "AT",
  "azerbaijan": "AZ",
  "bahamas": "BS",
  "bahrain": "BH",
  "bangladesh": "BD",
  "barbados": "BB",
  "belarus": "BY",
  "belgium": "BE",
  "belize": "BZ",
  "benin": "BJ",
  "bhutan": "BT",
  "bolivia": "BO",
  "bosnia-and-herzegovina": "BA",
  "botswana": "BW",
  "brazil": "BR",
  "brunei": "BN",
  "bulgaria": "BG",
  "burkina-faso": "BF",
  "burundi": "BI",
  "cabo-verde": "CV",
  "cambodia": "KH",
  "cameroon": "CM",
  "canada": "CA",
  "chad": "TD",
  "chile": "CL",
  "china": "CN",
  "colombia": "CO",
  "comoros": "KM",
  "congo": "CG",
  "cuba": "CU",
  "cyprus": "CY",
  "denmark": "DK",
  "djibouti": "DJ",
  "dominica": "DM",
  "dominican-republic": "DO",
  "ecuador": "EC",
  "egypt": "EG",
  "eritrea": "ER",
  "estonia": "EE",
  "eswatini": "SZ",
  "ethiopia": "ET",
  "fiji": "FJ",
  "finland": "FI",
  "france": "FR",
  "gabon": "GA",
  "gambia": "GM",
  "georgia": "GE",
  "germany": "DE",
  "ghana": "GH",
  "greece": "GR",
  "grenada": "GD",
  "guatemala": "GT",
  "guinea": "GN",
  "guinea-bissau": "GW",
  "guyana": "GY",
  "haiti": "HT",
  "honduras": "HN",
  "hungary": "HU",
  "iceland": "IS",
  "india": "IN",
  "indonesia": "ID",
  "iran": "IR",
  "iraq": "IQ",
  "ireland": "IE",
  "israel": "IL",
  "italy": "IT",
  "jamaica": "JM",
  "japan": "JP",
  "jordan": "JO",
  "kazakhstan": "KZ",
  "kenya": "KE",
  "kiribati": "KI",
  "korea-north": "KP",
  "korea-south": "KR",
  "kosovo": "XK",
  "kuwait": "KW",
  "kyrgyzstan": "KG",
  "laos": "LA",
  "latvia": "LV",
  "lebanon": "LB",
  "lesotho": "LS",
  "liberia": "LR",
  "libya": "LY",
  "liechtenstein": "LI",
  "lithuania": "LT",
  "luxembourg": "LU",
  "madagascar": "MG",
  "malawi": "MW",
  "malaysia": "MY",
  "maldives": "MV",
  "mali": "ML",
  "malta": "MT",
  "marshall-islands": "MH",
  "mauritania": "MR",
  "mauritius": "MU",
  "mexico": "MX",
  "micronesia": "FM",
  "moldova": "MD",
  "monaco": "MC",
  "mongolia": "MN",
  "montenegro": "ME",
  "morocco": "MA",
  "mozambique": "MZ",
  "myanmar": "MM",
  "namibia": "NA",
  "nauru": "NR",
  "nepal": "NP",
  "netherlands": "NL",
  "nicaragua": "NI",
  "niger": "NE",
  "nigeria": "NG",
  "norway": "NO",
  "oman": "OM",
  "pakistan": "PK",
  "palau": "PW",
  "palestine": "PS",
  "panama": "PA",
  "paraguay": "PY",
  "peru": "PE",
  "philippines": "PH",
  "poland": "PL",
  "portugal": "PT",
  "qatar": "QA",
  "romania": "RO",
  "russia": "RU",
  "rwanda": "RW",
  "samoa": "WS",
  "san-marino": "SM",
  "sao-tome-and-principe": "ST",
  "saudi-arabia": "SA",
  "senegal": "SN",
  "serbia": "RS",
  "seychelles": "SC",
  "singapore": "SG",
  "slovakia": "SK",
  "slovenia": "SI",
  "solomon-islands": "SB",
  "somalia": "SO",
  "south-africa": "ZA",
  "south-sudan": "SS",
  "spain": "ES",
  "sri-lanka": "LK",
  "sudan": "SD",
  "suriname": "SR",
  "sweden": "SE",
  "switzerland": "CH",
  "syria": "SY",
  "taiwan": "TW",
  "tajikistan": "TJ",
  "tanzania": "TZ",
  "thailand": "TH",
  "togo": "TG",
  "tonga": "TO",
  "tunisia": "TN",
  "turkey": "TR",
  "turkmenistan": "TM",
  "tuvalu": "TV",
  "uganda": "UG",
  "ukraine": "UA",
  "uruguay": "UY",
  "uzbekistan": "UZ",
  "vanuatu": "VU",
  "vatican-city": "VA",
  "venezuela": "VE",
  "vietnam": "VN",
  "yemen": "YE",
  "zambia": "ZM",
  "zimbabwe": "ZW",
  "french-guiana": "GF",
  "french-polynesia": "PF",
  "gibraltar": "GI",
  "greenland": "GL",
  "guadeloupe": "GP",
  "guam": "GU",
  "isle-of-man": "IM",
  "hong-kong": "HK",
  "jersey": "JE",
  "macao": "MO",
  "marie-galante": "GP",
  "martinique": "MQ",
  "mayotte": "YT",
  "montserrat": "MS",
  "macedonia": "MK",
  "northern-cyprus": "TR",
  "puerto-rico-us": "PR",
  "reunion": "RE",
  "saint-barthelemy": "BL",
  "saint-kitts-and-nevis": "KN",
  "saint-lucia": "LC",
  "saint-martinfrench-part": "MF",
  "saint-vincent-and-the-grenadines": "VC",
  "sierra-leone": "SL",
  "sint-maartendutch-part": "SX",
  "south-korea": "KR",
  "timor-leste": "TL",
  "trinidad-and-tobago": "TT",
  "turks-and-caicos-islands": "TC",
  "united-arab-emirates": "AE",
  "united-kingdom": "GB",
  "united-states": "US",
  "azores": "PT",
  "madeira": "PT",
  "new-zealand": "NZ",
  // "northern-cyprus": "TR",
  "palestine-state-of": "PS",
  "papua-new-guinea": "PG",
  "scotland": "GB",
  "bermuda": "BM",
  "bonaire": "BQ",
  "british-virgin-islands": "VG",
  "canary-islands": "ES",
  "cape-verde": "CV",
  "cayman-islands": "KY",
  "costa-rica": "CR",
  "cote-divoire": "CI",
  "curacao": "CW",
  "czech-republic": "CZ",
  "democratic-republic-of-the-congo": "CD",
  "el-salvador": "SV",
  "faroe-islands": "FO"
}

// private countryCodes = countryCodes;
export let getCountryCode = (countryName: string) => {
  // return countryCodes[countryName.charAt(0).toUpperCase() + countryName.slice(1)] ;
  return countryCodes[countryName];
};
export const countries = [
  { "name": "Afghanistan" },
  { "name": "Albania" },
  { "name": "Algeria" },
  { "name": "Andorra" },
  { "name": "Angola" },
  { "name": "Antigua and Barbuda" },
  { "name": "Argentina" },
  { "name": "Armenia" },
  { "name": "Australia" },
  { "name": "Austria" },
  { "name": "Azerbaijan" },
  { "name": "Bahamas" },
  { "name": "Bahrain" },
  { "name": "Bangladesh" },
  { "name": "Barbados" },
  { "name": "Belarus" },
  { "name": "Belgium" },
  { "name": "Belize" },
  { "name": "Benin" },
  { "name": "Bhutan" },
  { "name": "Bolivia" },
  { "name": "Bosnia and Herzegovina" },
  { "name": "Botswana" },
  { "name": "Brazil" },
  { "name": "Brunei" },
  { "name": "Bulgaria" },
  { "name": "Burkina Faso" },
  { "name": "Burundi" },
  { "name": "Cabo Verde" },
  { "name": "Cambodia" },
  { "name": "Cameroon" },
  { "name": "Canada" },
  { "name": "Central African Republic" },
  { "name": "Chad" },
  { "name": "Chile" },
  { "name": "China" },
  { "name": "Colombia" },
  { "name": "Comoros" },
  { "name": "Congo, Democratic Republic of the" },
  { "name": "Congo, Republic of the" },
  { "name": "Costa Rica" },
  { "name": "Croatia" },
  { "name": "Cuba" },
  { "name": "Cyprus" },
  { "name": "Czechia" },
  { "name": "Denmark" },
  { "name": "Djibouti" },
  { "name": "Dominica" },
  { "name": "Dominican Republic" },
  { "name": "East Timor" },
  { "name": "Ecuador" },
  { "name": "Egypt" },
  { "name": "El Salvador" },
  { "name": "Equatorial Guinea" },
  { "name": "Eritrea" },
  { "name": "Estonia" },
  { "name": "Eswatini" },
  { "name": "Ethiopia" },
  { "name": "Fiji" },
  { "name": "Finland" },
  { "name": "France" },
  { "name": "Gabon" },
  { "name": "Gambia" },
  { "name": "Georgia" },
  { "name": "Germany" },
  { "name": "Ghana" },
  { "name": "Greece" },
  { "name": "Grenada" },
  { "name": "Guatemala" },
  { "name": "Guinea" },
  { "name": "Guinea-Bissau" },
  { "name": "Guyana" },
  { "name": "Haiti" },
  { "name": "Honduras" },
  { "name": "Hungary" },
  { "name": "Iceland" },
  { "name": "India" },
  { "name": "Indonesia" },
  { "name": "Iran" },
  { "name": "Iraq" },
  { "name": "Ireland" },
  { "name": "Israel" },
  { "name": "Italy" },
  { "name": "Jamaica" },
  { "name": "Japan" },
  { "name": "Jordan" },
  { "name": "Kazakhstan" },
  { "name": "Kenya" },
  { "name": "Kiribati" },
  { "name": "Korea, North" },
  { "name": "Korea, South" },
  { "name": "Kosovo" },
  { "name": "Kuwait" },
  { "name": "Kyrgyzstan" },
  { "name": "Laos" },
  { "name": "Latvia" },
  { "name": "Lebanon" },
  { "name": "Lesotho" },
  { "name": "Liberia" },
  { "name": "Libya" },
  { "name": "Liechtenstein" },
  { "name": "Lithuania" },
  { "name": "Luxembourg" },
  { "name": "Madagascar" },
  { "name": "Malawi" },
  { "name": "Malaysia" },
  { "name": "Maldives" },
  { "name": "Mali" },
  { "name": "Malta" },
  { "name": "Marshall Islands" },
  { "name": "Mauritania" },
  { "name": "Mauritius" },
  { "name": "Mexico" },
  { "name": "Micronesia" },
  { "name": "Moldova" },
  { "name": "Monaco" },
  { "name": "Mongolia" },
  { "name": "Montenegro" },
  { "name": "Morocco" },
  { "name": "Mozambique" },
  { "name": "Myanmar" },
  { "name": "Namibia" },
  { "name": "Nauru" },
  { "name": "Nepal" },
  { "name": "Netherlands" },
  { "name": "New Zealand" },
  { "name": "Nicaragua" },
  { "name": "Niger" },
  { "name": "Nigeria" },
  { "name": "North Macedonia" },
  { "name": "Norway" },
  { "name": "Oman" },
  { "name": "Pakistan" },
  { "name": "Palau" },
  { "name": "Palestine" },
  { "name": "Panama" },
  { "name": "Papua New Guinea" },
  { "name": "Paraguay" },
  { "name": "Peru" },
  { "name": "Philippines" },
  { "name": "Poland" },
  { "name": "Portugal" },
  { "name": "Qatar" },
  { "name": "Romania" },
  { "name": "Russia" },
  { "name": "Rwanda" },
  { "name": "Saint Kitts and Nevis" },
  { "name": "Saint Lucia" },
  { "name": "Saint Vincent and the Grenadines" },
  { "name": "Samoa" },
  { "name": "San Marino" },
  { "name": "Sao Tome and Principe" },
  { "name": "Saudi Arabia" },
  { "name": "Senegal" },
  { "name": "Serbia" },
  { "name": "Seychelles" },
  { "name": "Sierra Leone" },
  { "name": "Singapore" },
  { "name": "Slovakia" },
  { "name": "Slovenia" },
  { "name": "Solomon Islands" },
  { "name": "Somalia" },
  { "name": "South Africa" },
  { "name": "South Sudan" },
  { "name": "Spain" },
  { "name": "Sri Lanka" },
  { "name": "Sudan" },
  { "name": "Suriname" },
  { "name": "Sweden" },
  { "name": "Switzerland" },
  { "name": "Syria" },
  { "name": "Taiwan" },
  { "name": "Tajikistan" },
  { "name": "Tanzania" },
  { "name": "Thailand" },
  { "name": "Togo" },
  { "name": "Tonga" },
  { "name": "Trinidad and Tobago" },
  { "name": "Tunisia" },
  { "name": "Turkey" },
  { "name": "Turkmenistan" },
  { "name": "Tuvalu" },
  { "name": "Uganda" },
  { "name": "Ukraine" },
  { "name": "United Arab Emirates" },
  { "name": "United Kingdom" },
  { "name": "United States" },
  { "name": "Uruguay" },
  { "name": "Uzbekistan" },
  { "name": "Vanuatu" },
  { "name": "Vatican City" },
  { "name": "Venezuela" },
  { "name": "Vietnam" },
  { "name": "Yemen" },
  { "name": "Zambia" },
  { "name": "Zimbabwe" }
]

// export const countries : {country:string,flag_url:string }[] = [
//   {"country": "Afghanistan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_Afghanistan.svg"},
//   {"country": "Albania", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg"},
//   {"country": "Algeria", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg"},
//   {"country": "Andorra", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Andorra.svg"},
//   {"country": "Angola", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg"},
//   {"country": "Antigua and Barbuda", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Antigua_and_Barbuda.svg"},
//   {"country": "Argentina", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg"},
//   {"country": "Armenia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Armenia.svg"},
//   {"country": "Australia", "flag_url": "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg"},
//   {"country": "Austria", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg"},
//   {"country": "Azerbaijan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg"},
//   {"country": "Bahamas", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Bahamas.svg"},
//   {"country": "Bahrain", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg"},
//   {"country": "Bangladesh", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg"},
//   {"country": "Barbados", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Barbados.svg"},
//   {"country": "Belarus", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Belarus.svg"},
//   {"country": "Belgium", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg"},
//   {"country": "Belize", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg"},
//   {"country": "Benin", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg"},
//   {"country": "Bhutan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/70/Flag_of_Bhutan.svg"},
//   {"country": "Bolivia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Bolivia.svg"},
//   {"country": "Bosnia and Herzegovina", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Bosnia_and_Herzegovina.svg"},
//   {"country": "Botswana", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Botswana.svg"},
//   {"country": "Brazil", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg"},
//   {"country": "Brunei", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg"},
//   {"country": "Bulgaria", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg"},
//   {"country": "Burkina Faso", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg"},
//   {"country": "Burundi", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg"},
//   {"country": "Cabo Verde", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Cape_Verde.svg"},
//   {"country": "Cambodia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg"},
//   {"country": "Cameroon", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg"},
//   {"country": "Canada", "flag_url": "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg"},
//   {"country": "Central African Republic", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_the_Central_African_Republic.svg"},
//   {"country": "Chad", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg"},
//   {"country": "Chile", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg"},
//   {"country": "China", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_China.svg"},
//   {"country": "Colombia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Colombia.svg"},
//   {"country": "Comoros", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_the_Comoros.svg"},
//   {"country": "Congo, Democratic Republic of the", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_the_Democratic_Republic_of_the_Congo.svg"},
//   {"country": "Congo, Republic of the", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_the_Republic_of_the_Congo.svg"},
//   {"country": "Costa Rica", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Costa_Rica.svg"},
//   {"country": "Croatia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg"},
//   {"country": "Cuba", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/24/Flag_of_Cuba.svg"},
//   {"country": "Cyprus", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg"},
//   {"country": "Czechia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg"},
//   {"country": "Denmark", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg"},
//   {"country": "Djibouti", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/54/Flag_of_Djibouti.svg"},
//   {"country": "Dominica", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Dominica.svg"},
//   {"country": "Dominican Republic", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_Dominican_Republic.svg"},
//   {"country": "Ecuador", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg"},
//   {"country": "Egypt", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Egypt.svg"},
//   {"country": "El Salvador", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_El_Salvador.svg"},
//   {"country": "Equatorial Guinea", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg"},
//   {"country": "Eritrea", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg"},
//   {"country": "Estonia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg"},
//   {"country": "Eswatini", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Eswatini.svg"},
//   {"country": "Ethiopia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg"},
//   {"country": "Fiji", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Fiji.svg"},
//   {"country": "Finland", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg"},
//   {"country": "France", "flag_url": "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"},
//   {"country": "Gabon", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gabon.svg"},
//   {"country": "Gambia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_the_Gambia.svg"},
//   {"country": "Georgia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Georgia.svg"},
//   {"country": "Germany", "flag_url": "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"},
//   {"country": "Ghana", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg"},
//   {"country": "Greece", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg"},
//   {"country": "Grenada", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1e/Flag_of_Grenada.svg"},
//   {"country": "Guatemala", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Guatemala.svg"},
//   {"country": "Guinea", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guinea.svg"},
//   {"country": "Guinea-Bissau", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Guinea-Bissau.svg"},
//   {"country": "Guyana", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg"},
//   {"country": "Haiti", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5d/Flag_of_Haiti.svg"},
//   {"country": "Honduras", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg"},
//   {"country": "Hungary", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg"},
//   {"country": "Iceland", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Iceland.svg"},
//   {"country": "India", "flag_url": "https://upload.wikimedia.org/wikipedia/en/7/7b/Flag_of_India.svg"},
//   {"country": "Indonesia", "flag_url": "https://upload.wikimedia.org/wikipedia/en/9/9f/Flag_of_Indonesia.svg"},
//   {"country": "Iran", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Iran.svg"},
//   {"country": "Iraq", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Iraq.svg"},
//   {"country": "Ireland", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg"},
//   {"country": "Israel", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg"},
//   {"country": "Italy", "flag_url": "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg"},
//   {"country": "Jamaica", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/0e/Flag_of_Jamaica.svg"},
//   {"country": "Japan", "flag_url": "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg"},
//   {"country": "Jordan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg"},
//   {"country": "Kazakhstan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg"},
//   {"country": "Kenya", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg"},
//   {"country": "Kiribati", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/62/Flag_of_Kiribati.svg"},
//   {"country": "Korea, North", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg"},
//   {"country": "Korea, South", "flag_url": "https://upload.wikimedia.org/wikipedia/en/0/09/Flag_of_South_Korea.svg"},
//   {"country": "Kosovo", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Kosovo.svg"},
//   {"country": "Kuwait", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg"},
//   {"country": "Kyrgyzstan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg"},
//   {"country": "Laos", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg"},
//   {"country": "Latvia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg"},
//   {"country": "Lebanon", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg"},
//   {"country": "Lesotho", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_Lesotho.svg"},
//   {"country": "Liberia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Liberia.svg"},
//   {"country": "Libya", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg"},
//   {"country": "Liechtenstein", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Liechtenstein.svg"},
//   {"country": "Lithuania", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg"},
//   {"country": "Luxembourg", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Luxembourg.svg"},
//   {"country": "Madagascar", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Madagascar.svg"},
//   {"country": "Malawi", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5f/Flag_of_Malawi.svg"},
//   {"country": "Malaysia", "flag_url": "https://upload.wikimedia.org/wikipedia/en/6/66/Flag_of_Malaysia.svg"},
//   {"country": "Maldives", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/81/Flag_of_the_Maldives.svg"},
//   {"country": "Mali", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg"},
//   {"country": "Malta", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg"},
//   {"country": "Marshall Islands", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_the_Marshall_Islands.svg"},
//   {"country": "Mauritania", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg"},
//   {"country": "Mauritius", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg"},
//   {"country": "Mexico", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg"},
//   {"country": "Micronesia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Federated_States_of_Micronesia.svg"},
//   {"country": "Moldova", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg"},
//   {"country": "Monaco", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Monaco.svg"},
//   {"country": "Mongolia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg"},
//   {"country": "Montenegro", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6c/Flag_of_Montenegro.svg"},
//   {"country": "Morocco", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg"},
//   {"country": "Mozambique", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mozambique.svg"},
//   {"country": "Myanmar", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg"},
//   {"country": "Namibia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/44/Flag_of_Namibia.svg"},
//   {"country": "Nauru", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg"},
//   {"country": "Nepal", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg"},
//   {"country": "Netherlands", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg"},
//   {"country": "New Zealand", "flag_url": "https://upload.wikimedia.org/wikipedia/en/3/3f/Flag_of_New_Zealand.svg"},
//   {"country": "Nicaragua", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1d/Flag_of_Nicaragua.svg"},
//   {"country": "Niger", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Niger.svg"},
//   {"country": "Nigeria", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/70/Flag_of_Nigeria.svg"},
//   {"country": "North Macedonia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_North_Macedonia.svg"},
//   {"country": "Norway", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg"},
//   {"country": "Oman", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg"},
//   {"country": "Pakistan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg"},
//   {"country": "Palau", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg"},
//   {"country": "Palestine", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg"},
//   {"country": "Panama", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_Panama.svg"},
//   {"country": "Papua New Guinea", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Papua_New_Guinea.svg"},
//   {"country": "Paraguay", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg"},
//   {"country": "Peru", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg"},
//   {"country": "Philippines", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg"},
//   {"country": "Poland", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg"},
//   {"country": "Portugal", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg"},
//   {"country": "Qatar", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Qatar.svg"},
//   {"country": "Romania", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg"},
//   {"country": "Russia", "flag_url": "https://upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg"},
//   {"country": "Rwanda", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg"},
//   {"country": "Saint Kitts and Nevis", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Saint_Kitts_and_Nevis.svg"},
//   {"country": "Saint Lucia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Saint_Lucia.svg"},
//   {"country": "Saint Vincent and the Grenadines", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/dc/Flag_of_Saint_Vincent_and_the_Grenadines.svg"},
//   {"country": "Samoa", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Samoa.svg"},
//   {"country": "San Marino", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/a8/Flag_of_San_Marino.svg"},
//   {"country": "Sao Tome and Principe", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Sao_Tome_and_Principe.svg"},
//   {"country": "Saudi Arabia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"},
//   {"country": "Senegal", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg"},
//   {"country": "Serbia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Serbia.svg"},
//   {"country": "Seychelles", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/3f/Flag_of_Seychelles.svg"},
//   {"country": "Sierra Leone", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg"},
//   {"country": "Singapore", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg"},
//   {"country": "Slovakia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg"},
//   {"country": "Slovenia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg"},
//   {"country": "Solomon Islands", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/7c/Flag_of_the_Solomon_Islands.svg"},
//   {"country": "Somalia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg"},
//   {"country": "South Africa", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_South_Africa.svg"},
//   {"country": "South Sudan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/7d/Flag_of_South_Sudan.svg"},
//   {"country": "Spain", "flag_url": "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg"},
//   {"country": "Sri Lanka", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg"},
//   {"country": "Sudan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg"},
//   {"country": "Suriname", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg"},
//   {"country": "Sweden", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg"},
//   {"country": "Switzerland", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg"},
//   {"country": "Syria", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/d/dc/Flag_of_Syria.svg"},
//   {"country": "Taiwan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/80/Flag_of_Taiwan.svg"},
//   {"country": "Tajikistan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/7c/Flag_of_Tajikistan.svg"},
//   {"country": "Tanzania", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/3a/Flag_of_Tanzania.svg"},
//   {"country": "Thailand", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg"},
//   {"country": "Timor-Leste", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Timor-Leste.svg"},
//   {"country": "Togo", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg"},
//   {"country": "Tonga", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Tonga.svg"},
//   {"country": "Trinidad and Tobago", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg"},
//   {"country": "Tunisia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Tunisia.svg"},
//   {"country": "Turkey", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg"},
//   {"country": "Turkmenistan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Turkmenistan.svg"},
//   {"country": "Tuvalu", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/5/5d/Flag_of_Tuvalu.svg"},
//   {"country": "Uganda", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Uganda.svg"},
//   {"country": "Ukraine", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Ukraine.svg"},
//   {"country": "United Arab Emirates", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_the_United_Arab_Emirates.svg"},
//   {"country": "United Kingdom", "flag_url": "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_Kingdom.svg"},
//   {"country": "United States", "flag_url": "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"},
//   {"country": "Uruguay", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg"},
//   {"country": "Uzbekistan", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg"},
//   {"country": "Vanuatu", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Vanuatu.svg"},
//   {"country": "Vatican City", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_the_Vatican_City.svg"},
//   {"country": "Venezuela", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/7/7d/Flag_of_Venezuela.svg"},
//   {"country": "Vietnam", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg"},
//   {"country": "Yemen", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Yemen.svg"},
//   {"country": "Zambia", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg"},
//   {"country": "Zimbabwe", "flag_url": "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg"}
// ]

