import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'come-reseller',
  templateUrl: './come-reseller.component.html',
  styleUrls: ['./come-reseller.component.scss']
})
export class ComeResellerComponent implements OnInit {
sellerForm:FormGroup=this.formBuilder.group({
  name:['',Validators.required],
  email:['',Validators.required],
  skype:['',Validators.required],
  websitePage:[''],
  facebookPage:[''],
  whatsapp_telegram:[''],
  averageSales:[''],
  payMethod:[''],
  additionalInfo:['',Validators.required],
})
  constructor(private formBuilder:FormBuilder) { }

  ngOnInit(): void {
  }

}
