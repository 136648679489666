import { Action, createAction, props } from '@ngrx/store';
import {
  TicketReplay,
  TicketSystem,
} from '../models/interfaces/tieckets-system';
export const CREATE_TICKETS = createAction(
  '[create ticket] createTicket',
  props<{ payload: any }>()
);
export const CREATE_TICKETS_SUCCESS = createAction(
  '[create ticket] createTicket success',
  props<{ payload: any }>()
);
export const CREATE_TICKETS_FAIL = createAction(
  '[create ticket] createTicket fail',
  props<{ payload: any }>()
);
export const GET_ALL_TICKETS = createAction('[get all tickets] getAllTickets');
export const GET_ALL_TICKETS_SUCCESS = createAction(
  '[get all tickets] getAllTickets success',
  props<{ payload: TicketSystem[] }>()
);
export const GET_ALL_TICKETS_FAIL = createAction(
  '[get all tickets] getAllTickets fail',
  props<{ payload: any }>()
);
export const GET_TICKET_BY_ID = createAction(
  '[get ticket by id] getTicketById',
  props<{ payload: string }>()
);
export const GET_TICKET_BY_ID_SUCCESS = createAction(
  '[get ticket by id] getTicketById success',
  props<{ payload: TicketSystem }>()
);
export const GET_TICKET_BY_ID_FAIL = createAction(
  '[get ticket by id] getTicketById fail',
  props<{ payload: any }>()
);
export const GET_ALL_USER_TICKETS = createAction(
  '[get all user tickets] getAllUserTickets',
  props<{ payload: any }>()
);
export const GET_ALL_USER_TICKETS_SUCCESS = createAction(
  '[get all user tickets] getAllUserTickets success',
  props<{ payload: { data: TicketSystem[] } }>()
);
export const GET_ALL_USER_TICKETS_FAIL = createAction(
  '[get all user tickets] getAllUserTickets fail',
  props<{ payload: any }>()
);
export const GET_USER_TICKET_BY_ID = createAction(
  '[get user ticket by id] getUserTicketById',
  props<{ payload: string }>()
);
export const GET_USER_TICKET_BY_ID_SUCCESS = createAction(
  '[get user ticket by id] getUserTicketById success',
  props<{ payload: TicketSystem }>()
);
export const GET_USER_TICKET_BY_ID_FAIL = createAction(
  '[get user ticket by id] getUserTicketById fail',
  props<{ payload: any }>()
);
export const DELETE_TICKET_BY_ID = createAction(
  '[delete ticket by id] deleteTicketById',
  props<{ payload: string }>()
);
export const DELETE_TICKET_BY_ID_SUCCESS = createAction(
  '[delete ticket by id] deleteTicketById success',
  props<{ payload: TicketSystem }>()
);
export const DELETE_TICKET_BY_ID_FAIL = createAction(
  '[delete ticket by id] deleteTicketById fail',
  props<{ payload: any }>()
);
export const CLOSE_TICKET_BY_ID = createAction(
  '[close ticket by id] closeTicketById',
  props<{ payload: string }>()
);
export const CLOSE_TICKET_BY_ID_SUCCESS = createAction(
  '[close ticket by id] closeTicketById success',
  props<{ payload: TicketSystem }>()
);
export const CLOSE_TICKET_BY_ID_FAIL = createAction(
  '[close ticket by id] closeTicketById fail',
  props<{ payload: any }>()
);
export const REPLY_TICKET = createAction(
  '[reply ticket] replyTicket',
  props<{ payload: TicketReplay }>()
);
export const REPLY_TICKET_SUCCESS = createAction(
  '[reply ticket] replyTicket success',
  props<{ payload: TicketSystem }>()
);
export const REPLY_TICKET_FAIL = createAction(
  '[reply ticket] replyTicket fail',
  props<{ payload: any }>()
);
export const UPDATE_TICKET_STATUS = createAction(
  '[update ticket] updateTicketStatus',
  props<{ payload: { id: string; query?: string } }>()
);
export const UPDATE_TICKET_STATUS_SUCCESS = createAction(
  '[update ticket] updateTicketStatus success',
  props<{ payload: TicketSystem }>()
);
export const UPDATE_TICKET_STATUS_FAIL = createAction(
  '[update ticket] updateTicketStatus fail',
  props<{ payload: any }>()
);
export interface ActionWithPayload<T> extends Action {
  payload: T;
}
