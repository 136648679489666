import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContactUs, ContactUsMessage } from '../models/interfaces/ContactUs';
@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  private apiURL = `${env.apiUrl}contact-us`;
  private apiReplayURL = `${env.apiUrl}mail/reply`;
  constructor(private http: HttpClient) { }

  creatContactUs(emailDeatails: any): Observable<any> {
    return this.http.post(`${this.apiURL}`, emailDeatails, { withCredentials: true, });
  }
  creatReplay(emailDeatails: any): Observable<any> {
    return this.http.post(`${this.apiReplayURL}`, emailDeatails, { withCredentials: true, });
  }
  getContactUs(param: {} = {}): Observable<ContactUs> {
    const params = new HttpParams({ fromObject: param });
    return this.http.get<ContactUs>(`${this.apiURL}?${params}`, {
      withCredentials: true,
    });
  }
  getOneContactUs(id: any): Observable<ContactUsMessage> {
    return this.http.get<ContactUsMessage>(`${this.apiURL}/${id}`, {
      withCredentials: true,
    });
  }

}
