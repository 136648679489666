import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';

@Component({
  selector: 'app-nav-footer-layout',
  templateUrl: './nav-footer-layout.component.html',
  styleUrls: ['./nav-footer-layout.component.scss']
})
export class NavFooterLayoutComponent implements OnInit {

  constructor(
    private storeChangesServ: StoreChangesService,
    private messageService: MessageService,) { }

  ngOnInit(): void {
    // this.loginMessage()
  }


}
