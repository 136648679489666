import { Licenses } from './../models/license';
import { ProductCreated, ReturnedProducts } from './../models/product';
import { Action, createAction, props } from "@ngrx/store";
import { ICreateLicensesRequest, IDeleteLicensesRequest, LicenseRes } from "../models/license";
import { Count } from '../models/order';
import { IProduct } from '../models/interfaces/product.model';
export const onGetAllProducts = createAction('[Products List] getAllProducts', props<{ payload: any }>());
export const onGetAllProductsSucceed = createAction('[Products List] getAllProducts success', props<{ payload: ReturnedProducts }>());
export const onGetAllProductsFailed = createAction('[Products List] getAllProducts fail', props<{ payload: Array<any> }>());

export const onAddProduct = createAction('[Create Product] onAddProduct', props<{ payload: IProduct | any }>());
export const onAddProductSucceed = createAction('[Create Product] onAddProduct Success', props<{ payload: ProductCreated }>());
export const onAddProductFailed = createAction('[Create Product] onAddProduct Fail', props<{ payload: any }>());
export const onGetProductById = createAction('[Get Product By Id] onGetProductById', props<{ payload: string }>());
export const onGetProductByIdSucceed = createAction('[Get Product By Id] onGetProductById Success', props<{ payload: IProduct }>());
export const onGetProductByIdFailed = createAction('[Get Product By Id] onGetProductById Fail', props<{ payload: any }>());
export const onUpdateProduct = createAction('[Update Product] onUpdateProduct', props<{ payload: IProduct|any }>());
export const onUpdateProductSucceed = createAction('[Update Product] onUpdateProduct Success', props<{ payload: IProduct }>());
export const onUpdateProductFailed = createAction('[Update Product] onUpdateProduct Fail', props<{ payload: any }>());
export const onRestoreProduct = createAction('[Update Product] onRestoreProduct', props<{ payload: string }>());
export const onRestoreProductSucceed = createAction('[Update Product] onRestoreProduct Success', props<{ payload: any }>());
export const onRestoreProductFailed = createAction('[Update Product] onRestoreProduct Fail', props<{ payload: any }>());
export const onDeleteProduct = createAction('[Delete Product] onDeleteProduct', props<{ payload: string }>());
export const onDeleteProductSucceed = createAction('[Delete Product] onDeleteProduct Success', props<{ payload: any }>());
export const onDeleteProductFailed = createAction('[Delete Product] onDeleteProduct Fail', props<{ payload: any }>());
export const onGetAllLicenses = createAction('[Licenses List] getAllLicenses', props<{ payload: string }>());
export const onGetAllLicensesSucceed = createAction('[Licenses List] getAllLicenses success', props<{ payload: Array<LicenseRes> }>());
export const onGetAllLicensesFailed = createAction('[Licenses List] getAllLicenses fail', props<{ payload: Array<any> }>());
export const onGetDeletedLicenses = createAction('[Licenses List] getDeletedLicenses', props<{ payload: string }>());
export const onGetDeletedLicensesSucceed = createAction('[Licenses List] getDeletedLicenses success', props<{ payload: Array<LicenseRes> }>());
export const onGetDeletedLicensesFailed = createAction('[Licenses List] getDeletedLicenses fail', props<{ payload: Array<any> }>());
export const onGetLicense = createAction('[Licens ] getLicense', props<{ payload: string }>());
export const onGetLicenseSucceed = createAction('[License ] getLicense success', props<{ payload: Licenses }>());
export const onGetLicenseFailed = createAction('[License ] getLicense fail', props<{ payload: any }>());
export const onAddLicense = createAction('[Create License] onAddLicense', props<{ payload: ICreateLicensesRequest }>());
export const onAddLicenseSucceed = createAction('[Create License] onAddLicense Success', props<{ payload: Array<LicenseRes> }>());
export const onAddLicenseFailed = createAction('[Create License] onAddLicense Fail', props<{ payload: any }>());
export const onDeleteLicenses = createAction('[Delete Licenses] onDeleteLicenses', props<{ payload: string }>());
export const onDeleteLicensesSucceed = createAction('[Delete Licenses] onDeleteLicenses Success', props<{ payload: any }>());
export const onDeleteLicensesFailed = createAction('[Delete Licenses] onDeleteLicenses Fail', props<{ payload: any }>());
export const COUNT_LICENSES = createAction('[Count Lincenses] countLicenses', props<{ payload: any }>())
export const COUNT_LICENSES_SUCCESS = createAction('[Count Licenses] countLicenses success', props<{ payload: Array<Count> }>())
export const COUNT_LICENSES_FAIL = createAction('[Count Licenses] countLicenses fail', props<{ payload: any }>())

export interface ActionWithPayload<T> extends Action { payload: T; }
