import { ProductType } from 'projects/shared/models/enums/productTypes.enum';
import { ICartItem } from 'projects/shared/models/interfaces/cart.model';
export class CartHelper {
  constructor() { }
  public groupCartItems(cartItems: ICartItem[]): ICartItem[] {
    const groupedCartItems: ICartItem[] = [];
    for (const cartItem of cartItems) {
      const similarCartItems = cartItems.filter((item) => {
        return item.product === cartItem.product;
      });
      const quantity = this.getSumOfQuantity(similarCartItems);
      let rangePrice: number = 0;
      similarCartItems.map((item) => {
        rangePrice += item.rangePrice;
      });
      const inputsKeys = cartItem.userInputs?.map((item: any) => {
        return item.inputsLabel;
      }) as string[];
      let inputs, userInputs: any;
      if (cartItem.userInputs) {
        inputs = cartItem.userInputs;
        const inputValues = Object.values(inputs);
        userInputs = {};
        for (const key of inputsKeys) {
          const index = inputsKeys.indexOf(key);
          userInputs[key] = inputValues[index];
        }
      }

      const groupedCartItem = {
        ...cartItem,
        rangePrice,
        userInputs,
        quantity,
      };
      groupedCartItems.push(groupedCartItem);
    }
    const response = this.getUniqueItems(groupedCartItems);
    return response;
  }
  public getSumOfQuantity(cartItems: ICartItem[]): number {
    let sum = 0;
    for (const cartItem of cartItems)
      if (cartItem.quantity) sum += cartItem.quantity;
    return sum;
  }
  private getUniqueItems(groupCartItems: ICartItem[]): ICartItem[] {
    const uniqueIds: string[] = [];
    const unique = groupCartItems.filter((element) => {
      const elementId = element.product as string;
      const isDuplicate = uniqueIds.includes(elementId);
      if (!isDuplicate) uniqueIds.push(elementId);
      return !isDuplicate;
    });
    return unique;
  }

  sumAndRemoveDuplicates(cartItems: ICartItem[] | any) {
    const resultMap = new Map<string, ICartItem[]>();

    for (const item of cartItems) {
      const key = `${item.product}_${item.rangePrice}_${JSON.stringify(item.userInputs)}`;
      const matchingItems = resultMap.get(key);

      if (matchingItems && matchingItems.length > 0) {
        const matchingItem = matchingItems[0];
        if (this.deepEqual(matchingItem.userInputs, item.userInputs)) {
          matchingItem.quantity += item.quantity;
          matchingItem.price += item.price;
        } else {
          matchingItems.push({ ...item });
        }
      } else {
        resultMap.set(key, [{ ...item }]);
      }
    }

    cartItems = Array.from(resultMap.values()).reduce(
      (acc, val) => acc.concat(val),
      []
    );
    return cartItems;
  }

  deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }
}
