import {
    Action,
    createAction,
    props,
} from "@ngrx/store";
import { ICheckoutResponse } from "../models/checkout";

/************* Checkout ************************/
export const CHECKOUT = createAction(
    '[Checkout] checkout',
    props<{ payload: any }>(),
);
export const CHECKOUT_SUCCESS = createAction(
    '[Checkout] checkout success',
    props<{ payload: any }>(),
);
export const CHECKOUT_FAIL = createAction(
    '[Checkout] checkout fail',
    props<{ payload: any }>(),
);

/************* Compolete Order ************************/
export const COMPLETE_ORDER = createAction(
    '[complete Order] completeOrder',
    props<{ payload: string }>(),
);
export const COMPLETE_ORDER_SUCCESS = createAction(
    '[complete Order] completeOrder Success',
    props<{ payload: any }>(),
);
export const COMPLETE_ORDER_FAIL = createAction(
    '[complete Order] completeOrder Fail',
    props<{ payload: any }>(),
);

/************* Return Order ************************/
export const RETURN_ORDER = createAction(
    '[return Order] returnOrder',
    props<{ payload: string }>(),
);
export const RETURN_ORDER_SUCCESS = createAction(
    '[return Order] returnOrder Success',
    props<{ payload: any }>(),
);
export const RETURN_ORDER_FAIL = createAction(
    '[return Order] returnOrder Fail',
    props<{ payload: any }>(),
);

export interface ActionWithPayload<T> extends Action {
    payload: T;
}
