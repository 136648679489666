import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarHeaderComponent } from './components/navbar-header/navbar-header.component';
import { NavbarLinksComponent } from './components/navbar-links/navbar-links.component';
import { NavbarMainComponent } from './pages/navbar-main/navbar-main.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { RouterModule } from '@angular/router';
import { OutsideClickDirective } from 'projects/shared/directives/outSideClick.directive';
import { CoreModule } from '../../+shared/modules/core.module';
import { SidebarModule } from 'primeng/sidebar';



@NgModule({
  declarations: [
    NavbarHeaderComponent,
    NavbarLinksComponent,
    NavbarMainComponent,
    OutsideClickDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    AvatarModule,
    RouterModule,
    CoreModule,
    SidebarModule
  ],
  exports: [
    NavbarMainComponent
  ]
})
export class NavbarMainModule { }
