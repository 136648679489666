import { catchError, map, mergeMap, of, retry, tap } from 'rxjs';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { MediaService } from "../../services/media.service";
import * as FAQActions from '../../actions/media.action'
@Injectable()
export class FAQEffects {
  constructor(private actions$: Actions, private mediaService: MediaService) { }
  // public getFAQs$ = createEffect(() => this.actions$.pipe(
  //   ofType(FAQActions.GET_FAQs.type),
  //   mergeMap((action: FAQActions.ActionWithPayload<any>) => {
  //     return this.mediaService.getFAQs().pipe(
  //       retry(1),
  //       map((response) => FAQActions.GET_FAQs_SUCCESS({ payload: response })),
  //       catchError((error) => of(FAQActions.GET_FAQs_FAILED({ payload: error }))),
  //     );
  //   }),
  // ));
  public errorOnGetFAQs$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.GET_FAQs_FAILED.type),
    tap((action: FAQActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  // public getFAQ$ = createEffect(() => this.actions$.pipe(
  //   ofType(FAQActions.GET_FAQ.type),
  //   mergeMap((action: FAQActions.ActionWithPayload<any>) => {
  //     return this.mediaService.getFAQ(action.payload).pipe(
  //       retry(1),
  //       map((response) => FAQActions.GET_FAQ_SUCCESS({ payload: response })),
  //       catchError((error) => of(FAQActions.GET_FAQ_FAILED({ payload: error }))),
  //     );
  //   }),
  // ));
  public errorOnGetFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.GET_FAQ_FAILED.type),
    tap((action: FAQActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  public createFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.CREAT_FAQ.type),
    mergeMap((action: FAQActions.ActionWithPayload<any>) => {
      return this.mediaService.createFAQ(action.payload).pipe(
        retry(1),
        map((response) => FAQActions.CREAT_FAQ_SUCCESS({ payload: response })),
        catchError((error) => of(FAQActions.CREAT_FAQ_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorOnCreateFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.CREAT_FAQ_FAILED.type),
    tap((action: FAQActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  public updateFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.UPDATE_FAQ.type),
    mergeMap((action: FAQActions.ActionWithPayload<any>) => {
      return this.mediaService.updateFAQ(action.payload).pipe(
        retry(1),
        map((response) => FAQActions.UPDATE_FAQ_SUCCESS({ payload: response })),
        catchError((error) => of(FAQActions.UPDATE_FAQ_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorOnUpdateFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.UPDATE_FAQ_FAILED.type),
    tap((action: FAQActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );
  public deleteFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.DELETE_FAQ.type),
    mergeMap((action: FAQActions.ActionWithPayload<any>) => {
      return this.mediaService.deleteFAQ(action.payload).pipe(
        retry(1),
        map((response) =>{return FAQActions.DELETE_FAQ_SUCCESS({ payload: response })}),
        catchError((error) => of(FAQActions.DELETE_FAQ_FAILED({ payload: error }))),
      );
    }),
  ));
  public errorOnDeleteFAQ$ = createEffect(() => this.actions$.pipe(
    ofType(FAQActions.DELETE_FAQ_FAILED.type),
    tap((action: FAQActions.ActionWithPayload<any>) => action.payload)),
    { dispatch: false },
  );

}
