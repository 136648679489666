import { Action, createAction, props } from '@ngrx/store';
import { Notification } from '../models/interfaces/notification';
/************* get all notifications ************************/
export const GET_ALL_NOTIFICATIONS = createAction(
  '[Notifications List] getAllNotifications'
);
export const GET_ALL_NOTIFICATIONS_SUCCESS = createAction(
  '[Notifications List] getAllNotifications success',
  props<{ payload: Array<Notification> }>()
);
export const GET_ALL_NOTIFICATIONS_FAIL = createAction(
  '[Notifications List] getAllNotifications fail',
  props<{ payload: Array<any> }>()
);
/************* get all notifications ************************/
export const GET_USER_NOTIFICATIONS = createAction(
  '[Notifications List] getUserNotifications'
);
export const GET_USER_NOTIFICATIONS_SUCCESS = createAction(
  '[Notifications List] getUserNotifications success',
  props<{ payload: Array<Notification> }>()
);
export const GET_USER_NOTIFICATIONS_FAIL = createAction(
  '[Notifications List] getUserNotifications fail',
  props<{ payload: Array<any> }>()
);
/************* get notification by id ************************/
export const GET_NOTIFICATION = createAction(
  '[Get Notification] getNotification',
  props<{ payload: string }>()
);
export const GET_NOTIFICATION_SUCCESS = createAction(
  '[Get Notification] getNotification success',
  props<{ payload: Notification }>()
);
export const GET_NOTIFICATION_FAIL = createAction(
  '[Get Notification] getNotification fail',
  props<{ payload: any }>()
);
/************* Create notification ************************/
export const CREATE_NOTIFICATION = createAction(
  '[Create Notifications] createNotification',
  props<{ payload: Notification }>()
);
export const CREATE_NOTIFICATION_SUCCESS = createAction(
  '[Create Notifications] createNotification success',
  props<{ payload: Notification }>()
);
export const CREATE_NOTIFICATION_FAIL = createAction(
  '[Create Notifications] createNotification fail',
  props<{ payload: any }>()
);
/************* Read notification ************************/
export const READ_NOTIFICATION = createAction(
  '[Read Notifications] readNotification',
  props<{ payload: string }>()
);
export const READ_NOTIFICATION_SUCCESS = createAction(
  '[Read Notifications] readNotification success',
  props<{ payload: Notification }>()
);
export const READ_NOTIFICATION_FAIL = createAction(
  '[Read Notifications] readNotification fail',
  props<{ payload: any }>()
);
/************* Create notification ************************/
export const DELETE_NOTIFICATION = createAction(
  '[Delete Notifications] deleteNotification',
  props<{ payload: string }>()
);
export const DELETE_NOTIFICATION_SUCCESS = createAction(
  '[Delete Notifications] deleteNotification success',
  props<{ payload: any }>()
);
export const DELETE_NOTIFICATION_FAIL = createAction(
  '[Delete Notifications] deleteNotification fail',
  props<{ payload: any }>()
);

export interface ActionWithPayload<T> extends Action {
  payload: T;
}
