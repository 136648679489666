import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import * as cartActions from '../actions/cart.action';
import { CartService } from '../services/cart.service';
import { ICart, ICartItem } from '../models/interfaces/cart.model';
@Injectable()
export class CartEffects {
  constructor(private actions$: Actions, private cartService: CartService) { }
  // Main Category effects
  public addToCart$ = createEffect(() => this.actions$.pipe(
    ofType(cartActions.ADD_TO_CART.type),
    mergeMap((action: cartActions.ActionWithPayload<ICartItem[]>) => {
      return this.cartService.addToCart(action.payload).pipe(
        retry(1),
        map((response) => { return cartActions.ADD_TO_CART_SUCCESS({ payload: response }) }),
        catchError((error) => of(cartActions.ADD_TO_CART_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnAddToCart$ = createEffect(() => this.actions$.pipe(
    ofType(cartActions.ADD_TO_CART_FAIL.type),
    tap((action: cartActions.ActionWithPayload<HttpErrorResponse>) => { return action.payload.error })),
    { dispatch: false },
  );
  public getCart$ = createEffect(() => this.actions$.pipe(
    ofType(cartActions.GET_CART.type),
    mergeMap((action: cartActions.ActionWithPayload<ICart>) => {
      return this.cartService.getCart().pipe(
        retry(1),
        map((response) => cartActions.GET_CART_SUCCESS({ payload: response })),
        catchError((error) => of(cartActions.GET_CART_FAIL({ payload: error }))),
      );
    }),
  ));

  public errorOnGetCart$ = createEffect(() => this.actions$.pipe(
    ofType(cartActions.GET_CART_FAIL.type),
    tap((action: cartActions.ActionWithPayload<HttpErrorResponse>) => { return action.payload.error })),
    { dispatch: false },
  );
}
