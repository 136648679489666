import { HttpErrorResponse } from '@angular/common/http';
import { FAQResponse } from '../../models/media';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as FAQActions from '../../actions/media.action';
import { FAQ } from '../../models/media';
export const FAQFeatureKey = 'FAQModule';
export const adapter: EntityAdapter<FAQ> = createEntityAdapter<FAQ>({ selectId: selectWalletId });
export function selectWalletId(a: FAQ): string { return a?._id as string; }
export interface FeatureState extends EntityState<FAQ> {
 FAQ: FAQResponse;
 getFAQ:FAQ;
}
export interface AppState { FAQModule: FeatureState; }

export const selectFeature = (state: AppState) => state.FAQModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureFAQ = createSelector(selectFeature, (state: FeatureState) => state);
export const selectAllFAQEntities = createSelector(selectFeatureFAQ, selectAll);
export const selectFAQ = createSelector(selectFeatureFAQ,(state: FeatureState) => state.FAQ);
export const selectFAQById = createSelector(selectFeatureFAQ,(state: FeatureState) => state.getFAQ);

export const initialState: FeatureState = adapter.getInitialState({
 FAQ:{},
 getFAQ:{}
});
let deletedId: string;
const walletReducer = createReducer(
  initialState,
  on(FAQActions.GET_FAQs, (state) => { return adapter.removeAll(state); }),
  on(FAQActions.GET_FAQs_FAILED, (state) => ({ ...state })),
  on(FAQActions.GET_FAQs_SUCCESS, (state, action) => {
    return adapter.addMany(action.payload, state);
  }),
  on(FAQActions.GET_FAQ, (state) => ({ ...state, getFAQ: {} })),
  on(FAQActions.GET_FAQ_FAILED, (state, action) => {return  { ...state, getFAQ: action.payload };}),
  on(FAQActions.GET_FAQ_SUCCESS, (state, action) => { return adapter.addOne(action.payload, { ...state, getFAQ: action.payload }); }),
  
  on(FAQActions.CREAT_FAQ, (state) => ({ ...state, FAQ: {} })),
  on(FAQActions.CREAT_FAQ_FAILED, (state, action) => {return  { ...state, FAQ: action.payload };}),
  on(FAQActions.CREAT_FAQ_SUCCESS, (state, action) => { return adapter.addOne(action.payload, { ...state, FAQ: action.payload }); }),

  on(FAQActions.UPDATE_FAQ, (state) => ({ ...state, FAQ: {} })),
  on(FAQActions.UPDATE_FAQ_FAILED, (state,action) => ({ ...state, FAQ: action.payload })),
  on(FAQActions.UPDATE_FAQ_SUCCESS, (state, action) => {return { ...state, FAQ: action.payload };
  }),
  on(FAQActions.DELETE_FAQ, (state, action) => {
    deletedId = action.payload;
    return { ...state, FAQ: {} };
  }),
  on(FAQActions.DELETE_FAQ_FAILED, (state,action) => ({ ...state, FAQ: action.payload })),
  on(FAQActions.DELETE_FAQ_SUCCESS, (state, action) => { return adapter.removeOne(deletedId, state) }),


);
export function reducer(state: FeatureState, action: Action) { return walletReducer(state, action); }
