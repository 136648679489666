<!-- <div class="container-fluid">
  <div class="row mt-4">
    <div class="col-12">
      <div class="d-flex justify-content-center header">
        <h3>Become One of our Resellers.</h3>
      </div>
    </div>
    <div class="col-12 mt-3 title">
      <div class="container">
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-12 col-lg-6 mt-4">
            <div class="card question-card p-5">
              <h5 class="pb-2 text-white-50">ANY QUESTIONS?</h5>
              <h3 class="pb-2 mb-4">Frequent Asked Questions</h3>
              <div class="accordion" id="faq">
                <div class="card mb-2">
                  <div class="card-header" id="faqhead1">
                    <a
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                      class="btn btn-header-link collapsed"
                      >benefits of register as a reseller ?</a
                    >
                  </div>

                  <div
                    id="faq1"
                    class="collapse"
                    aria-labelledby="faqhead1"
                  >
                    <div class="card-body">
                      <ul>
                        <li>
                          you will get all your order instantly as the delivery
                          is automatically.
                        </li>
                        <li>there is no fees on any of your orders.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card mb-2">
                  <div class="card-header" id="faqhead2">
                    <a
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                      >how can i register as a reseller ?</a
                    >
                  </div>

                  <div
                    id="faq2"
                    class="collapse"
                    aria-labelledby="faqhead2"
                  >
                    <div class="card-body">
                      <ul>
                        <li>
                          you will get all your order instantly as the delivery
                          is automatically.
                        </li>
                        <li>there is no fees on any of your orders.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="card mb-2">
                  <div class="card-header" id="faqhead3">
                    <a
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                      >how it works?</a
                    >
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                  >
                    <div class="card-body">
                      <ul>
                        <li>
                          you will get all your order instantly as the delivery
                          is automatically.
                        </li>
                        <li>there is no fees on any of your orders.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-4">
            <span class="text-info">LET'S TALK</span>
            <h3 class="bold">Fill This Form</h3>
            <div class="card form-container">
              <form [formGroup]="sellerForm">
                <div class="form-group">
                  <input
                    type="username"
                    class="form-control"
                    id="name"
                    placeholder="Name"
                    formControlName="name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    formControlName="email"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="skype"
                    placeholder="Skype"
                    formControlName="skype"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="website"
                    placeholder="Website page"
                    formControlName="websitePage"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="Facebook"
                    placeholder="Facebook page"
                    formControlName="facebookPage"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="whatsapp_telegram"
                    placeholder="Whatsapp / Telegram"
                    formControlName="whatsapp_telegram"
                  />
                </div>
                <div class="form-group">
                  <select class="form-control" id="averageSales"
                  formControlName="averageSales">
                    <option selected="selected" disabled="disabled">Average Sales Per Month</option>
                    <option>{{'0 to 500 $'}}</option>
                    <option>{{'500 to 10k $'}}</option>
                    <option>{{'10k to 50k $'}}</option>
                    <option>{{'50k $ < '}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    id="payMethod"
                    rows="2"
                    placeholder="What Payment Methods You whould Prefer"
                    formControlName="payMethod"
                  ></textarea>
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    id="additionalInfo"
                    rows="3"
                    placeholder="tell us what products you are interset in"
                    formControlName="additionalInfo"
                  ></textarea>
                </div>
                <button class="btn btn-info float-right mb-2 mr-2"
                [disabled]="sellerForm.invalid">
                  REQUEST CONSULTATION
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<section class="resellers">
  <div class="container position-relative">
    <h2 class="title-footer">
      Become One of our Resellers.
    </h2>
    <div class="row">
      <div class="col-md-12">
        <p class="desc mb-0">With supporting text below as a natural lead-in to additional content.
        </p>
      </div>
    </div>
  </div>
</section>
