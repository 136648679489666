import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ticketSystemActions from '../actions/ticket-system.action';
import { catchError, map, mergeMap, of, retry, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TicketSystemService } from '../services/ticket-system.service';
import {
  TicketReplay,
  TicketSystem,
} from '../models/interfaces/tieckets-system';
@Injectable()
export class TicketSystemEffects {
  constructor(
    private actions$: Actions,
    private ticketSystemService: TicketSystemService
  ) {}

  public loadAllUserTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.GET_ALL_USER_TICKETS.type),
      mergeMap((action: ticketSystemActions.ActionWithPayload<any>) => {
        return this.ticketSystemService.userTickets(action.payload).pipe(
          retry(1),
          map((response) => {
            return ticketSystemActions.GET_ALL_USER_TICKETS_SUCCESS({
              payload: response,
            });
          }),
          catchError((error) =>
            of(
              ticketSystemActions.GET_ALL_USER_TICKETS_FAIL({ payload: error })
            )
          )
        );
      })
    )
  );
  public errorOnLoadAllUserTickets$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ticketSystemActions.GET_ALL_USER_TICKETS_FAIL.type),
        tap((action: ticketSystemActions.ActionWithPayload<any>) =>
          console.log(action.payload)
        )
      ),
    { dispatch: false }
  );
  public getTicketEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.GET_TICKET_BY_ID.type),
      mergeMap((action: ticketSystemActions.ActionWithPayload<string>) => {
        return this.ticketSystemService.getTicket(action.payload).pipe(
          retry(1),
          map((response) => {
            return ticketSystemActions.GET_TICKET_BY_ID_SUCCESS({
              payload: response,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              ticketSystemActions.GET_TICKET_BY_ID_FAIL({
                payload: error.error,
              })
            )
          )
        );
      })
    )
  );
  public errorGetTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ticketSystemActions.GET_TICKET_BY_ID_FAIL.type),
        tap((action: ticketSystemActions.ActionWithPayload<any>) => {
          return action.payload;
        })
      ),
    { dispatch: false }
  );
  public getUserTicketEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.GET_USER_TICKET_BY_ID.type),
      mergeMap((action: ticketSystemActions.ActionWithPayload<string>) => {
        return this.ticketSystemService.userTicket(action.payload).pipe(
          retry(1),
          map((response) => {
            return ticketSystemActions.GET_USER_TICKET_BY_ID_SUCCESS({
              payload: response,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              ticketSystemActions.GET_USER_TICKET_BY_ID_FAIL({
                payload: error.error,
              })
            )
          )
        );
      })
    )
  );
  public errorGetUserTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ticketSystemActions.GET_USER_TICKET_BY_ID_FAIL.type),
        tap((action: ticketSystemActions.ActionWithPayload<any>) => {
          return action.payload;
        })
      ),
    { dispatch: false }
  );
  public createTicketEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.CREATE_TICKETS.type),
      mergeMap(
        (action: ticketSystemActions.ActionWithPayload<TicketSystem>) => {
          return this.ticketSystemService.createTickets(action.payload).pipe(
            retry(1),
            map((response) => {
              return ticketSystemActions.CREATE_TICKETS_SUCCESS({
                payload: response,
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                ticketSystemActions.CREATE_TICKETS_FAIL({
                  payload: error.error,
                })
              )
            )
          );
        }
      )
    )
  );
  public errorCreateTicket$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ticketSystemActions.CREATE_TICKETS_FAIL.type),
        tap((action: ticketSystemActions.ActionWithPayload<any>) => {
          return action.payload;
        })
      ),
    { dispatch: false }
  );
  public closeTicketEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.CLOSE_TICKET_BY_ID.type),
      mergeMap((action: ticketSystemActions.ActionWithPayload<string>) => {
        return this.ticketSystemService.closeTicket(action.payload).pipe(
          retry(1),
          map((response) => {
            return ticketSystemActions.CLOSE_TICKET_BY_ID_SUCCESS({
              payload: response,
            });
          }),
          catchError((error) =>
            of(ticketSystemActions.CLOSE_TICKET_BY_ID_FAIL({ payload: error }))
          )
        );
      })
    )
  );
  // public replyTicketEffect$ = createEffect(() => this.actions$.pipe(
  //   ofType(ticketSystemActions.REPLY_TICKET.type),
  //   mergeMap((action: ticketSystemActions.ActionWithPayload<TicketReplay>) => {
  //     return this.ticketSystemService.replyTicket(action.payload).pipe(
  //       retry(1),
  //       map((response) => { return ticketSystemActions.REPLY_TICKET_SUCCESS({ payload: response }); }),
  //       catchError((error) => of(ticketSystemActions.REPLY_TICKET_FAIL({ payload: error }))),
  //     );
  //   }),
  // ));
  public updateTicketStatusEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.UPDATE_TICKET_STATUS.type),
      mergeMap(
        (
          action: ticketSystemActions.ActionWithPayload<{
            id: string;
            query: string;
          }>
        ) => {
          return this.ticketSystemService
            .changeTicketStatus(action.payload.id, action.payload.query)
            .pipe(
              retry(1),
              map((response) => {
                return ticketSystemActions.UPDATE_TICKET_STATUS_SUCCESS({
                  payload: response,
                });
              }),
              catchError((error) =>
                of(
                  ticketSystemActions.UPDATE_TICKET_STATUS_FAIL({
                    payload: error,
                  })
                )
              )
            );
        }
      )
    )
  );
  public deleteTicketEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketSystemActions.DELETE_TICKET_BY_ID.type),
      mergeMap((action: ticketSystemActions.ActionWithPayload<string>) => {
        return this.ticketSystemService.deleteTicket(action.payload).pipe(
          retry(1),
          map((response) => {
            return ticketSystemActions.DELETE_TICKET_BY_ID_SUCCESS({
              payload: response,
            });
          }),
          catchError((error) =>
            of(ticketSystemActions.DELETE_TICKET_BY_ID_FAIL({ payload: error }))
          )
        );
      })
    )
  );
}
