import { Injectable } from '@angular/core';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { MainCategory, SortCategoriesRequest } from '../../models/category';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromActions from '../../actions/category.action';
import * as fromFeature from '../../reducers/category/main-category.reducer';
@Injectable({ providedIn: 'root' })
export class MainCategoryStoreProvider {

  // constructor(public store: Store<fromFeature.AppState>) {}
  // public getAllMainCategories$(queryParam = ''): Observable<MainCategory[]> {
  //   this.store.dispatch(fromActions.onGetAllMainCategories({ payload: queryParam }));
  //   return this.store.pipe(select(fromFeature.selectAllMainCategoriesEntities), map((categories: MainCategory[]) => categories));
  // }
  // public getMainCategory$(id: string): Observable<MainCategory | undefined> {
  //   this.store.dispatch(fromActions.onGetMainCategoryById({ payload: id }));
  //   return this.store.pipe(select(fromFeature.selectMainCategory));
  // }
  // public createMainCategory$(mainCategoryData: MainCategory): Observable<MainCategory | undefined> {
  //   this.store.dispatch(fromActions.onAddMainCategory({ payload: mainCategoryData }));
  //   return this.store.pipe(select(fromFeature.selectAddedMainCategory));
  // }
  // public updateMainCategory$(mainCategoryData: MainCategory): Observable<MainCategory | undefined> {
  //   this.store.dispatch(fromActions.onUpdateMainCategory({ payload: mainCategoryData }));
  //   return this.store.pipe(select(fromFeature.selectMainCategory));
  // }
  // public getSortedMainCategories$(payload: SortCategoriesRequest): Observable<MainCategory[]> {
  //   this.store.dispatch(fromActions.onSortMainCategory({ payload }));
  //   return this.store.pipe(select(fromFeature.selectAllMainCategoriesEntities), map((categories: MainCategory[]) => categories));
  // }
  // public deleteMainCategory(id: string): void {
  //   this.store.dispatch(fromActions.onDeleteMainCategory({ payload: id }));
  // }
  // public restoreMainCategory(id: string): void {
  //   this.store.dispatch(fromActions.onRestoreMainCategory({ payload: id }));
  // }
}
