import { NgModule } from '@angular/core';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { FullContentLayoutComponent } from './components/full-content-layout/full-content-layout.component';
import { NavFooterLayoutComponent } from './components/nav-footer-layout/nav-footer-layout.component';
import { NavbarMainModule } from './navbar/navbar-main.module';
import { FooterModule } from '../components/footer/footer.module';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [FullContentLayoutComponent, NavFooterLayoutComponent],
  imports: [
    LayoutsRoutingModule,
    NavbarMainModule,
    FooterModule,
    ScrollTopModule,
    ToastModule
  ],
  exports: [FullContentLayoutComponent, NavFooterLayoutComponent],
  providers: [],
  bootstrap: [],
})
export class LayoutsModule {}
