import { ReturnedProducts } from './../models/product';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as productActions from '../actions/product.action';
import { IProduct } from '../models/product';
export const productsFeatureKey = 'productModule';
export const adapter: EntityAdapter<IProduct> = createEntityAdapter<IProduct>({ selectId: selectProductId });
export function selectProductId(a: IProduct): string { return a._id as string; }

export interface FeatureState extends EntityState<IProduct> {
  getAllProducts: ReturnedProducts;
  getProduct?: IProduct;
  addedProduct?: IProduct;
  updatedProduct?: IProduct;
  updateSucceeded: boolean;
}
export interface AppState { productModule: FeatureState; }
export const selectFeature = (state: AppState) => state.productModule;
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export const selectFeatureProducts = createSelector(selectFeature, (state: FeatureState) => state);
export const selectAddedProduct = createSelector(selectFeatureProducts, (state: FeatureState) => state.addedProduct);
export const selectUpdatedProduct = createSelector(selectFeatureProducts, (state: FeatureState) => state.updatedProduct);

export const selectAllProducts = createSelector(selectFeatureProducts, (state: FeatureState) => state.getAllProducts);

export const selectAllProductsEntities = createSelector(selectFeatureProducts, selectAll);
export const selectProduct = createSelector(selectFeatureProducts, (state: FeatureState) => state.getProduct);
export const initialState: FeatureState = adapter.getInitialState({
  getAllProducts: {},
  getProduct: {},
  addedProduct: {},
  updatedProduct: {},
  updateSucceeded: false,
});

let restoredId: string;
const productsReducer = createReducer(
  initialState,
  on(productActions.onGetAllProducts, (state) => ({ ...state })),
  on(productActions.onGetAllProductsFailed, (state) => ({ ...state })),
  on(productActions.onGetAllProductsSucceed, (state, action) => { return { ...state, getAllProducts: action.payload }; }),

  on(productActions.onGetProductById, (state) => ({ ...state, getProduct: {} })),
  on(productActions.onGetProductByIdFailed, (state) => ({ ...state })),
  on(productActions.onGetProductByIdSucceed, (state, action) => { return { ...state, getProduct: action.payload }; }),
  on(productActions.onAddProduct, (state) => ({ ...state, addedProduct: {} })),
  on(productActions.onAddProductFailed, (state) => ({ ...state, addedProduct: undefined })),
  on(productActions.onAddProductSucceed, (state, action) => { return adapter.addOne(action.payload, { ...state, addedProduct: action.payload }); }),
  on(productActions.onUpdateProduct, (state, action) => ({ ...state, updatedProduct: {}, updateSucceeded: false })),
  on(productActions.onUpdateProductFailed, (state) => ({ ...state, updatedProduct: undefined, updateSucceeded: false })),
  on(productActions.onUpdateProductSucceed, (state, action) => { return { ...state, updatedProduct: action.payload, updateSucceeded: true }; }),
  on(productActions.onRestoreProduct, (state, action) => {
    restoredId = action.payload;
    return { ...state };
  }),
  on(productActions.onRestoreProductFailed, (state) => ({ ...state })),
  on(productActions.onRestoreProductSucceed, (state, action) => { return adapter.removeOne(restoredId, state); }),
  on(productActions.onDeleteProduct, (state) => ({ ...state })),
  on(productActions.onDeleteProductFailed, (state) => ({ ...state })),
  on(productActions.onDeleteProductSucceed, (state, action) => { return { ...state }; }),

  on(productActions.COUNT_LICENSES, (state) => { return adapter.removeAll(state); }),
  on(productActions.COUNT_LICENSES_FAIL, (state) => ({ ...state })),
  on(productActions.COUNT_LICENSES_SUCCESS, (state, action) => { return adapter.addMany(action.payload, state); }),

);

export function reducer(state: FeatureState, action: Action) { return productsReducer(state, action); }
