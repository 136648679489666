import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartService } from '../services/cart.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard implements CanActivate {
  constructor(
    private cartServ: CartService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // let valid = sessionStorage.getItem('userExist')
    return this.cartServ.getCartNumber().pipe(map((cartItemsNum) => {
      if (cartItemsNum.totalQuantity > 0) {
        console.log(true)
        return true;
      } else {
        this.router.navigateByUrl('/settings/cart');
        console.log(false)
        return false;
      }
    })
    );
  }


  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
  //   this.cartServ.getCartNumber().subscribe((cartItemsNum) => {
  //     if (cartItemsNum.totalQuantity > 0) {
  //       console.log(true)
  //       return true;
  //     } else {
  //       this.router.navigateByUrl('/settings/cart');
  //       console.log(false)
  //       return false;
  //     }
  //   })
  // }
}
