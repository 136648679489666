import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IProduct, ProductCreated } from '../models/product';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import { ProductsService } from '../services/products.service';
import * as productActions from '../actions/product.action';
import { ICreateLicensesRequest, IDeleteLicensesRequest, LicenseRes } from '../models/license';
@Injectable()
export class ProductEffects {
  constructor(private actions$: Actions, private productService: ProductsService) { }
  public loadAllProducts$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetAllProducts.type),
    mergeMap((action: productActions.ActionWithPayload<any>) => {
      return this.productService.getProducts(action.payload).pipe(
        retry(1),
        map((response) => productActions.onGetAllProductsSucceed({ payload: response })),
        catchError((error) => of(productActions.onGetAllProductsFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadAllProducts$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetAllProductsFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => { return action.payload })),
    { dispatch: false },
  );
  public addProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onAddProduct.type),
    mergeMap((action: productActions.ActionWithPayload<IProduct|any>) => {
      return this.productService.createProduct(action.payload).pipe(
        retry(1),
        map((response) => {
          return productActions.onAddProductSucceed({ payload: response });
        }),
        catchError((error) => of(productActions.onAddProductFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnAddProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onAddProductFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public onGetProductById$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetProductById.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.getSingleProduct(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onGetProductByIdSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onGetProductByIdFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnGetProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetProductByIdFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public onUpdateProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onUpdateProduct.type),
    mergeMap((action: productActions.ActionWithPayload<IProduct|any>) => {
      return this.productService.updateProduct(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onUpdateProductSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onUpdateProductFailed({ payload: error }))),
      );
    }),
  ));
  public onRestoreProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onRestoreProduct.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.restoreProduct(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onRestoreProductSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onRestoreProductFailed({ payload: error }))),
      );
    }),
  ));
  public onDeleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onDeleteProduct.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.deleteProduct(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onDeleteProductSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onDeleteProductFailed({ payload: error }))),
      );
    }),
  ));
  public loadAllLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetAllLicenses.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.getLicenses(action.payload).pipe(
        retry(1),
        map((response) => productActions.onGetAllLicensesSucceed({ payload: response.data })),
        catchError((error) => of(productActions.onGetAllLicensesFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadAllLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetAllLicensesFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public loadDeletedLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetDeletedLicenses.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.getLicenses(action.payload).pipe(
        retry(1),
        map((response) => productActions.onGetDeletedLicensesSucceed({ payload: response.data })),
        catchError((error) => of(productActions.onGetDeletedLicensesFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnLoadDeletedLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetDeletedLicensesFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );

  public getLicense$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetLicense.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.getLicense(action.payload).pipe(
        retry(1),
        map((response) => {
          return productActions.onGetLicenseSucceed({ payload: response })
        }),
        catchError((error) => of(productActions.onGetLicenseFailed({ payload: error }))),
      );
    }),
  ));
  public errorOngetLicense$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onGetLicenseFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public addLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onAddLicense.type),
    mergeMap((action: productActions.ActionWithPayload<ICreateLicensesRequest>) => {
      return this.productService.createLicenses(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onAddLicenseSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onAddLicenseFailed({ payload: error }))),
      );
    }),
  ));
  public errorOnAddLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onAddLicenseFailed.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
  public onDeleteLicense$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.onDeleteLicenses.type),
    mergeMap((action: productActions.ActionWithPayload<string>) => {
      return this.productService.deleteLicenses(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.onDeleteLicensesSucceed({ payload: response }); }),
        catchError((error) => of(productActions.onDeleteLicensesFailed({ payload: error }))),
      );
    }),
  ));
  public countLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.COUNT_LICENSES.type),
    mergeMap((action: productActions.ActionWithPayload<any>) => {
      return this.productService.countLicenses(action.payload).pipe(
        retry(1),
        map((response) => { return productActions.COUNT_LICENSES_SUCCESS({ payload: response }); }),
        catchError((error) => of(productActions.COUNT_LICENSES_FAIL({ payload: error }))),
      );
    }),
  ));
  public errorCountLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(productActions.COUNT_LICENSES_FAIL.type),
    tap((action: productActions.ActionWithPayload<any>) => console.log(action.payload))),
    { dispatch: false },
  );
}
