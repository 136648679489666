import { Action, createAction, props } from "@ngrx/store";
import { ICart, ICartItem } from "../models/interfaces/cart.model";

export const ADD_TO_CART = createAction('[Add Cart] addToCart',props<{ payload: Array<ICartItem> }>());
export const ADD_TO_CART_SUCCESS = createAction('[Add Cart] addToCart success', props<{ payload: any}>());
export const ADD_TO_CART_FAIL = createAction('[Add Cart] addToCart fail', props<{ payload: any}>());

export const GET_CART=createAction('[Get cart] getCart')
export const GET_CART_SUCCESS=createAction('[Get cart] getCart success',props<{payload:ICart}>())
export const GET_CART_FAIL=createAction('[Get cart] getCart failed',props<{payload:any}>())

export interface ActionWithPayload<T> extends Action { payload: T; }
