<section class="not-found position-relative">
  <div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-md-12">
        <img alt="not found image" class="img-fluid" width="851" height="500"
          src="../../../../../assets/images/not-found/Group.svg" alt="not found 404 img" />
      </div>
      <div class="col-md-3 mt-3">
        <button class="btn-main" (click)="routeHome()">Go to Home Page.</button>
      </div>
    </div>
  </div>
</section>