export class Exchange {
  _id?: string;
  EGP?: number;
  EUR?: number;
  SAR?: number;
  AED?: number;
  status?: string;
  createdAt?: string;
}
export enum Currency {
  USD = "USD",
  EGP = "EGP",
  EUR = "EUR",
  SAR = "SAR",
  AED = "AED",
}
export enum CurrencyWallets {
  ALL = "",
  USD = "USD",
  EGP = "EGP",
  EUR = "EUR",
  SAR = "SAR",
  AED = "AED",
}
export enum Rank {
  'All' = "",
  Normal = "Normal",
  Diamond = "Diamond",
  Gold = "Gold",
  Silver = "Silver",
}
export enum Role {
  'All' = '',
  USER = 'USER',
  ADMIN = 'ADMIN',
  'EMPLOYER-I' = "EMPLOYER-I",
  'EMPLOYER-II' = "EMPLOYER-II",
  'EMPLOYER-III' = "EMPLOYER-III",
}

