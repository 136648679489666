<section class="terms">
  <div class="container position-relative">
    <div class="terms-container">
      <h1>Terms & Conditions</h1>
      <p>Welcome to Card4game store,</p>
      <p>DYJTAL KWD PORTAL LLC maintains the Store Card4game.com.</p>
      <p>Please read the following terms and conditions:</p>

      <h2>ABOUT THE WEBSITE</h2>
      <p>The United Arab Emirates is our country of domicile and stipulates that the governing law is the local law.</p>
      <p>We don't trade with or provide any services to OFAC and sanctioned countries listed by Visa, MasterCard, or
        accepted schemes.</p>
      <p>Customers using the website who are minors (under the age of 18) shall not register as Users of the website and
        shall not transact on or use the website.</p>
      <p>The user is responsible for maintaining the confidentiality of their account.</p>
      <p>Cardholders must retain a copy of transaction records and merchant policies and rules.</p>
      <p>The Website is an e-commerce platform allowing users to purchase various game cards and digital services.
        GameCardi reserves the right to introduce new services and update or withdraw any services at our sole
        discretion without any liability.</p>

      <h2>Client’s Obligations</h2>
      <p>You pledge, represent, and guarantee that:</p>

      <p>All applicable laws, statutes, and regulations—including, without limitation, all privacy laws and content
        regulations—must be complied with in full and will always be done so.</p>

      <p>The execution and performance of your obligations under these Terms of Use do not violate any laws, rules,
        regulations, or governmental directives to which you are subject.</p>

      <p>You have full capacity and authority to enter into these Terms of Use.</p>

      <p>If you create or use an account on behalf of a business entity, you indicate that you have the authority to
        bind the business and are bound by any other agreements to which you are a party or by which you are otherwise
        bound.</p>


      <h2>Data Collection and Usage</h2>
      <p>We collect personal data for various purposes, including:</p>
      <p>Executing purchase agreements, facilitating delivery, and handling payment settlements.Engaging in
        administrative and customer support activities.</p>
      <p>Enhancing and personalizing your shopping experience on our platform.</p>
      <p>Complying with legal obligations and safeguarding against fraudulent activities.</p>
      <p>Personal data we may collect includes:</p>

      <ul>
        <li>Name</li>
        <li>Address</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Payment details</li>
        <li>IP address</li>
        <li>Device Fingerprint</li>
        <li>Any other relevant data</li>
      </ul>

      <h2>WARRANTIES, REPRESENTATIONS & UNDERTAKINGS</h2>
      <p>In no event will we, our parent company, subsidiaries, affiliates, and our, and their, directors, officers,
        agents, employees, or suppliers be liable, whether based on an action or claim in contract, tort, negligence,
        breach of statutory duty, or otherwise arising out of or in relation to these Terms of Use, for loss of profits,
        loss of data or information, business interruption, or other monetary loss or for any special, indirect,
        incidental, or consequential damages, even if we have been negligent or violate

        Additionally, we (including our parent business, subsidiaries, and affiliates) are not responsible, and you
        agree not to hold (including our directors, officers, agents, employees, or suppliers)

        the content or other information you provide when using the
        services.</p>
      <p>Your use of or your inability to use our services. Pricing, shipping, format, or other guidance provided by us.
        Delays or disruptions in our services.</p>
      <p>Viruses or malicious software are obtained by accessing or linking to our services. Bugs, errors, or
        inaccuracies of any kind in our services. Damage to your hardware device from the use of products sold on the
        Website or our services.</p>
      <p>The content, actions, or inactions of third parties using our services.</p>
      <p>We suspended or taken other action concerning your use of the services.</p>
      <p>Your need to modify practices, content, or behavior or your loss of or inability to do business due to changes
        to these Terms of Use.</p>
      <p>You agree to indemnify and hold us, our parent company, subsidiaries and affiliates, and our, and their
        directors, officers, agents, employees or suppliers harmless from and against any losses, damages and expenses
        (including legal fees and attorney’s fees) (“Claims”) arising out of or relating to:</p>
      <p>Any claims or demands made by any third party due to or arising from your use of the services.</p>
      <p>your violation of any of the provisions of these Terms of Use, including, without limitation, any of the
        warranties, representations, and undertakings.</p>

      <h2>LIABILITY & INDEMNITIES</h2>
      <p>In no event will we, our parent company, subsidiaries, affiliates, and our, and their directors, officers,
        agents, employees, or suppliers be liable, whether based on an action or claim in contract, tort,</p>
      <p>negligence, breach of statutory duty, or otherwise arising out of or about these Terms of Use for loss of
        profits, loss of data or
        information, business interruption, or other monetary loss or for any special,</p>
      <p>indirect, incidental, or consequential damages, even if we have been negligent
        or violate </p>
      <p>Additionally, you acknowledge that we (including our parent business, subsidiaries, and affiliates, as well as
        our and their directors, officers, agents, employees, or suppliers) are not responsible and</p>

      <h2>REPORTING VIOLATIONS OF THESE TERMS OF USE</h2>
      <p>
        We are devoted to making sure that the material and items offered on our website abide by these Terms of Use.
        Please let us know if you think any listed items or material violates our terms of use, and we'll look into it.
      </p>

      <h2>Communications</h2>
      <p>You may contact us through email at <a href="mailto:sales@card4game.com">sales@card4game.com</a>.</p>
      <div class="rtl">
        <h2>سياسات الخدمة / شروط الاستخدام</h2>
        <p>بالتسجيل على موقعنا <a href="https://card4games.com">https://card4games.com </a>فأنت توافق على استلام رسائل
          على الإيميل أو رقمك المسجل
          لدينا.</p>
        <ul>
          <li>في حالة الشك في عملية الشراء يحق لنا أن نطلب بعض الوثائق الإضافية للتحقق.</li>
          <li>لابد أن تقوم بالدفع بالبطاقة الائتمانية الخاصة بك شخصيًا وليس بطاقة شخص آخر.</li>
          <li>يقر المستخدم بأن معاملات الدفع غير قابلة للاسترداد مادام الكود صالحًا للعمل وصحيحًا.</li>
          <li>مسؤوليتك عن الحفاظ على خصوصيتك هي أمر خاص بك.</li>
        </ul>
        <p>نحن نضمن لك الحفاظ على خصوصيتك وعلى جميع المعاملات التي تمت على موقعنا.</p>
      </div>
      <h2>يمكنك التواصل معنا هنا</h2>
      <p>For inquiries, please contact us at <a href="mailto:sales@card4game.com">sales@card4game.com</a> or
        +966552033409.</p>
    </div>
  </div>
</section>