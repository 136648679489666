import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafehtmlPipe } from '../pipes/safehtml.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { GFilterPipe } from '../pipes/public-filter.pipe';
import { MultiFilterPipe } from 'projects/shared/pipes/muti-filter.pipe';



@NgModule({
  declarations: [
    SafehtmlPipe,
    FilterPipe,
    GFilterPipe,
    MultiFilterPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafehtmlPipe,
    FilterPipe,
    GFilterPipe,
    MultiFilterPipe
  ]
})
export class CustomPipesModule { }
